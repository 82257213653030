@use '../abstracts' as *;

/* sitemap
*************************************************** */
.sitemap--menu {
  @include mq(med) {
    border-top: 1px solid rgba($color: $clr-body, $alpha: .2);
  }
  svg {
    fill: rgba($color: $clr-body, $alpha: .8);
  }
}
.sitemap--menu > .menu-item {
  @include mq(med) {
    border-bottom: 1px solid rgba($color: $clr-body, $alpha: .2);
  }
}
.sitemap--menu .head_sub_nav2 a {
  color: var(--clr-body, #333);
  svg {
    fill: var(--clr-body, #333);
  }
}
.sitemap--menu > .menu-item > a {
  color: rgba($color: $clr-body, $alpha: .8);
}