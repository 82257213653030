@use '../abstracts' as *;

/* contact
********************************************** */
.section-contact .cta_tel {
  width: 100%;
  max-width: 400px;
  @include auto-margin;
}
.section-contact .cta_tel a {
  display: block;
  padding: rem(24) rem(40);
  border: 2px solid;
  @include mq(sp) {
    padding: 24px;
  }
}