@use '../abstracts/' as *;

/* home_software
********************************************** */
  .home_software .ttl-03 {
    @include mq(sp) {
      font-size: .929rem; /* 13px */
      margin-bottom: 40px !important;
    }
  }
  .home_software--list {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 2px;
    letter-spacing: .08em;
    line-height: 1.6;
    @include mq(sp) {
      width: perc(414,350);
      margin-left: perc(-32,350);
      grid-template-columns: repeat(2,1fr);
    }
  }
  .home_software--list a {
    display: block;
    height: 100%;
    padding: perc(54,340) perc(59,340);
    background-color: rgba($color: #fff, $alpha: .5);
    @include mq(sp) {
      padding: perc(30,207);
    }
    &:not([href="javascript:void(0);"]):hover {
      background-color: rgba($color: #fff, $alpha: 1);
    }
  }
  .home_software--list img {
    margin-bottom: 1rem;
  }