@use '../abstracts/' as *;

/* home_works
********************************************** */
  .home_works.section_pdg {
    @include mq(sp) {
      padding-top: 56px;
    }
  }
  .posts-works_wraps {
    @include mq(sp) {
      width: perc(382,350);
      margin-bottom: 24px;
    }
  }
  .posts-works_wrap {
    @include mq(sp) {
      overflow: auto;
      padding-right: perc(32,350);
    }
    &:not(:first-child) {
      display: none;
    }
  }
  .page_ttl--list_txt {
    @include mq(sp) {
      background-color: var(--clr-wht, #fff);
      border-radius: rem(10);
      padding: 1em 1.5em;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 1.5em;
        margin: auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 6px 0 6px;
        border-color: #E2D8CE transparent transparent transparent;
      }
    }
  }
  .page_ttl--list_wrap {
    @include mq(sp) {
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 24px perc(32,414);
      z-index: z(middle);
      background-color: rgba($color: #fff, $alpha: .8);
      margin-bottom: 0;
      transition: var(--transit-default, all .4s ease-out);
      opacity: 0;
      pointer-events: none;
      &.active {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
  .page_ttl--list_close {
    @include mq(sp) {
      @include rect(48);
      margin-top: -52px;
      margin-bottom: 4px;
      margin-left: auto;
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 90%;
        height: 1px;
        border-bottom: 1px solid;
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }
  .page_ttl--list-works {
    @include mq(sp) {
      display: block;
      width: 100%;
      max-height: 476px;
      overflow: auto !important;
      box-shadow: 0 0 1rem rgba($color: #333, $alpha: .1);
      @media screen and (max-height: 667px) {
        max-height: 370px;
      }
    }
  }
  .page_ttl--list-works a {
    cursor: pointer;
  }
  .page_ttl--list-works li.select a {
    color: #fff;
    background-color: var(--clr-main, #1D7FB7);
  }
  .home_works .posts-works {
    @include mq(sp) {
      width: perc(812,347);
      gap: perc(16,812);
      margin-left: auto;
      margin-right: auto;
      &.cnt-1 {
        width: perc(260,347);
        gap: perc(16,260);
      }
      &.cnt-2 {
        width: perc(536,347);
        gap: perc(16,536);
      }
    }
  }
  .home_works .posts-works .post {
    @include mq(sp, min, ps) {
      &:first-child {
        margin-left: auto;
      }
      &:last-child {
        margin-right: auto;
      }
    }
    @include mq(sp) {
      width: auto;
      flex: 1;
    }
  }
  .home_works .posts-works .post--txtarea {
    @include mq(sp) {
      padding: rem(24) rem(20);
    }
  }
  .home_works .posts-works .post--ttl {
    @include mq(sp) {
      font-size: 1rem; /* 14px */
    }
  }
  .home_works .posts-works .post--txt {
    @include mq(sp) {
      font-size: .857rem; /* 12px */
    }
  }