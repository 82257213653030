@use '../abstracts' as *;

/* ##############################################################################

    Loops モジュール

############################################################################## */
  .btn .icon-svg {
    position: absolute;
    top: 50%;
    display: block;
    transform: translateY(-50%);
    transition: var(--transit-default, all .4s ease-out);
  }
  .icon-new_tab {
    width: rem(14);
    height: rem(14);
  }
  .btn .icon-new_tab {
    right: rem(30);
  }
  .icon-pdf {
    width: rem(27);
    height: rem(12);
  }
  .btn .icon-pdf {
    right: rem(20);
  }
  .lps_sec {
    position: relative;
  }
  .lps_sec-wh {
    z-index: 1;
    padding-bottom: 160px;
    @include mq(sp) {
      padding-bottom: 80px;
    }
    &:first-child {
      margin-top: 96px;
      @include mq(sp) {
        margin-top: 48px;
      }
    }
    &:last-child {
      margin-bottom: 96px;
      @include mq(sp) {
        margin-bottom: 48px;
      }
    }
    &.lps_sec-lft {
      &::before {
        left: 0;
        border-radius: 0 rem(20) rem(20) 0;
      }
      &::after {
        right: 0;
        border-radius: rem(20) 0 0 rem(20);
      }
      .deco {
        right: perc(-135,1200);
      }
    }
    &.lps_sec-rgt {
      &::before {
        right: 0;
        border-radius: rem(20) 0 0 rem(20);
        @include mq(sp) {
          border-radius: rem(10) 0 0 rem(10);
        }
      }
      &::after {
        left: 0;
        border-radius: 0 rem(20) rem(20) 0;
        @include mq(sp) {
          border-radius: 0 rem(10) rem(10) 0;
        }
      }
      .deco {
        left: perc(-135,1200);
      }
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
    }
    &::before {
      top: 96px;
      width: perc(1800,1920);
      height: calc(100% - 96px);
      background-color: var(--clr-wht, #fff);
      @include mq(sp) {
        top: 48px;
        width: perc(382,414);
        height: calc(100% - 48px);
      }
    }
    &::after {
      top: 0;
      width: perc(640,1920);
      height: 640px;
      background-color: var(--clr-main, #1D7FB7);
      z-index: -1;
      @include mq(sp) {
        width: perc(120,414);
      }
    }
    .inner {
      width: perc(1440,1920);
    }
    & > .ttl-01:first-child {
      margin-top: -78px;
      @include mq(sp) {
        margin-top: -44px;
      }
    }
    & > *:first-child:not(.ttl-01) {
      padding-top: 96px;
      .first {
        top: calc(96px - .6em);
      }
      @include mq(sp) {
        padding-top: 48px;
        .first {
          top: calc(48px - .4em);
        }
      }
    }
    .layout-wrap--slide {
      bottom: -124px;
      @include mq(sp) {
        bottom: -72px;
      }
    }

    /* カラム */
    .column-1 {
      .img-detail {
        border-radius: 0 !important;
        overflow: inherit !important;
        &::before {
          padding-top: perc(560,1200);
        }
        img:not(.deco) {
          border-radius: rem(20);
        }
      }
      .deco {
        position: absolute;
        bottom: perc(-44,560);
        width: perc(475,1200);
        height: auto;
      }
      .img + .text {
        padding-top: 124px;
        @include mq(sp) {
          padding-top: 40px;
        }
      }
      .text {
        width: 100%;
        max-width: 840px;
        margin-left: auto;
        margin-right: auto;
      }
      .mce-content-body p:not(:last-child) {
        margin-bottom: 2em;
      }
    }
    .column-1 + .lps_parts {
      padding-top: 72px;
      margin-top: 72px !important;
      @include mq(sp) {
        padding-top: 32px;
        margin-top: 32px !important;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 840px;
        margin-left: auto;
        margin-right: auto;
        background-image: linear-gradient(to right,var(--bg-off_wht, #f7f7f7),var(--bg-off_wht, #f7f7f7) 60%,transparent 60%,transparent 100%);
        background-size: 10px 10px;
        height: 2px;
        @include mq(sp) {
          max-width: perc(1440,1920);
        }
      }
      .inner-xs {
        max-width: 840px;
      }
    }

    /* 沿革 */
    .lps_parts--history .inner:not(.note)::before {
      background-color: rgba($color: #EEEDE6, $alpha: .5)
    }
  }
  .home_deco + .lps_sec-wh {
    margin-top: 96px;
    @include mq(sp) {
      margin-top: 48px;
    }
  }
  .lps_sec-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-position: center;
  }
  .lps_sec .cursor_wrap {
    position: absolute;
    top: 60%;
    left: -420px;
    transform: translateY(-50%);
    width: 840px;
    height: 840px;
    opacity: .5;
    animation: anime 80s linear infinite;
    z-index: -1;
    @include mq(sp) {
      left: -80px;
      width: 240px;
      height: 240px;
      animation: anime 40s linear infinite;
    }
  }

  @media screen and (max-width: 767px) {
    .btn .icon-new_tab {
      right: 24px;
    }
  }

/* lps_parts
********************************************** */
  .lps_parts {
    position: relative;
    z-index: 1;
  }
  .lps_parts .img-detail::before {
    padding-top: 66.666%;
  }
  .lps_parts + .ttl-02,
  .lps_parts--button + .ttl-02,
  .lps_parts--button + .lps_parts,
  .lps_parts + .lps_parts {
    margin-top: 80px;
    @include mq(sp) {
      margin-top: 40px;
    }
  }
  .lps_parts + .lps_parts--button {
    margin-top: 40px;
    @include mq(sp) {
      margin-top: 16px;
    }
  }
  .lps_parts iframe {
    width: 100%;
  }
  .lps_parts .text:not(:last-child) {
    margin-bottom: 24px;
  }
  .lps_parts--column .btn,
  .lps_parts--slide .btn {
    text-align: center;
  }
  .lps_parts--column *:not(.btn) + .btn {
    margin-top: auto;
  }
  .lps_parts--column .btn a {
    @include auto-margin;
  }
  .lps_parts .mce-content-body:not(:last-child) {
    margin-bottom: 40px;
    @include mq(sp) {
      margin-bottom: 16px;
    }
  }
  @include mq(sp) {
    .lps_parts .btn {
      text-align: center;
    }
    .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
    .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
    .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
      width: 100%;
      margin-right: 0;
    }
  }


/* ##############################################################################

    Loops調整

############################################################################## */

/* lps_parts--img_text
********************************************** */
  .lps_parts--img_text .img_text--img .img-detail {
    overflow: hidden;
  }
  .lps_parts--img_text .bg-wh:not(.inner-lg) {
    border-radius: rem(20);
  }
  .lps_parts--img_text .bg-wh .img-detail,
  .lps_parts--img_text .inner-lg.flex-reverse:not(.bg-wh) .img-detail {
    border-radius: rem(20) 0 0 rem(20);
  }
  .lps_parts--img_text .bg-wh.flex-reverse .img-detail,
  .lps_parts--img_text .inner-lg:not(.bg-wh) .img-detail {
    border-radius: 0 rem(20) rem(20) 0;
  }
  .lps_parts--img_text .inner-lg.bg-wh .img-detail {
    border-radius: 0;
  }
  .lps_parts--img_text .img-detail,
  .lps_parts--img_text .img-contain {
    border-radius: rem(20);
  }
  .lps_parts--img_text.has_deco:last-child {
    margin-bottom: 80px;
    @include mq(sp) {
      margin-bottom: 40px;
    }
  }
  .lps_parts--img_text.has_deco + .lps_parts {
    margin-top: 160px;
    @include mq(sp) {
      margin-top: 40px;
    }
  }
  .lps_parts--img_text .deco {
    bottom: perc(-88,427);
    width: perc(475,600);
    @include mq(sp) {
      bottom: perc(-44,427);
      width: perc(237.5,600);
    }
  }
  .lps_parts--img_text .lps_parts--child:not(.flex-reverse) .deco {
    left: perc(-80,600);
  }
  .lps_parts--img_text .lps_parts--child.flex-reverse .deco {
    right: perc(-80,600);
  }
  .lps_parts--img_text.img_wid-684 .lps_parts--child:not(.bg-wh) {
    @include mq(sp, min, ps) {
      max-width: 1213px;
      transform: translateX(-6.5px);
      column-gap: perc(81,1213);
      .img_text--img {
        width: perc(684,1213);
      }
      .img_text--txt {
        width: perc(448,1213);
      }
    }
  }
  .lps_parts--img_text.img_wid-594 .lps_parts--child:not(.bg-wh) {
    @include mq(sp, min, ps) {
      max-width: 1295px;
      transform: translateX(47.5px);
      column-gap: perc(99,1295);
      .img_text--img {
        width: perc(594,1295);
      }
      .img_text--txt {
        width: perc(602,1295);
      }
    }
  }

  @include mq(sp) {
    .lps_parts--img_text .bg-wh:not(.inner-lg) {
      border-radius: rem(10);
    }
    .lps_parts--img_text .bg-wh .img-detail,
    .lps_parts--img_text .inner-lg.flex-reverse:not(.bg-wh) .img-detail {
      border-radius: rem(10) 0 0 rem(10);
    }
    .lps_parts--img_text .bg-wh.flex-reverse .img-detail,
    .lps_parts--img_text .inner-lg:not(.bg-wh) .img-detail {
      border-radius: 0 rem(10) rem(10) 0;
    }
    .lps_parts--img_text .inner-lg.bg-wh .img-detail {
      border-radius: 0;
    }
    .lps_parts--img_text .img-detail,
    .lps_parts--img_text .img-contain {
      border-radius: rem(10);
    }
    .lps_parts--img_text .bg-wh .img-detail {
      border-radius: rem(10) rem(10) 0 0 !important;
    }
    .lps_parts--img_text .inner-lg .img-detail {
      border-radius: 0 !important;
    }
  }

/* lps_parts--column
********************************************** */
  .lps_parts--column:not(.corner) .column_item.bg-wh,
  .lps_parts--column:not(.corner) .column_item:not(.bg-wh) .img-detail,
  .lps_parts--slide .img {
    border-radius: rem(20);
    overflow: hidden;
    @include mq(sp) {
      border-radius: rem(20);
    }
  }
  .corner .btn a {
    border-radius: rem(5);
  }

/* lps_parts--faq
********************************************** */
  .lps_parts--faq .ttl-03 {
    border-radius: rem(20) rem(20) 0 0;
  }
  .lps_parts--faq .mce-content-body {
    border-radius: 0 0 rem(20) rem(20);
  }
  .lps_parts--flow .text img,
  .lps_parts--faq .text img {
    border-radius: rem(20);
  }

  @include mq(sp) {
    .lps_parts--faq .ttl-03 {
      border-radius: rem(10) rem(10) 0 0;
    }
    .lps_parts--faq .mce-content-body {
      border-radius: 0 0 rem(10) rem(10);
    }
    .lps_parts--flow .text img,
    .lps_parts--faq .text img {
      border-radius: rem(10);
    }
  }