@use '../abstracts' as * ;

/* ##############################################################################

    FOOTER

############################################################################## */

/* pagetop
********************************************** */
  .pagetop {
    position: fixed;
    bottom: 45px;
    right: perc(69,1920);
    transform: scaleY(-1);
    z-index: z(aside);
  }
  .pagetop p {
    transform: scaleY(-1);
    margin-top: 2em;
  }
  .pagetop .bar {
    width: 3px;
    height: 86px;
    background-color: var(--clr-main, #1D7FB7);
    transition: all .4s ease-out;
    animation: scroll_down 2s cubic-bezier(.5, -.1, .5, 1) infinite;
  }

/* footer_top
********************************************** */
  .footer {
    overflow: hidden;
  }
  .footer_top.section_pdg {
    padding-top: 128px;
    @include mq(sp) {
      padding-top: 64px;
      padding-bottom: 16px;
    }
  }
  .footer_top .inner {
    max-width: 400px;
  }
  .footer--logo:not(:last-child) {
    margin-bottom: 1rem;
  }
  .footer--logo a {
    display: table;
    margin-left: auto;
    margin-right: auto;
  }
  .footer--logo img {
    width: 401px;
    @include mq(sp) {
      width: 201px;
    }
  }
  .footer--catch {
    text-transform: uppercase;
    color: #D7D4BF;
    @include mq(sp) {
      font-size: sprem(10);
    }
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
  .footer .sns_area:not(:last-child) {
    margin-bottom: 24px;
    @include mq(sp) {
      margin-bottom: 32px;
    }
  }
  .footer--txt {
    opacity: .5;
  }
  .footer_btn--list {
    margin-top: 40px;
  }
  .footer_btn--list li {
    flex: 1;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  .footer_btn a {
    display: flex;
    align-items: center;
    border-radius: rem(10);
    padding: .72em .5em;
  }
  .footer_btn-reservation a {
    color: var(--clr-main, #1D7FB7);
    background-color: var(--clr-wht, #fff);
  }
  .footer_btn-contact a {
    color: var(--clr-wht, #fff);
    background-color: var(--clr-main, #1D7FB7);
  }
  .footer_btn .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    @include rect(60);
  }
  .footer_btn .txt {
    line-height: 1.4;
  }
  .footer_btn .font-en {
    display: block;
    opacity: .5;
    font-size: sprem(9);
    margin-top: .5em;
    @include mq(med, min, ps) {
      @media screen and (max-height: 870px) {
        height: vh(9);
      }
    }
  }

  /* ---sns_area --- */
  .sns--list:not(.flex) li:not(:last-child) {
    margin-bottom: 32px;
    @include mq(sp) {
      margin-bottom: 26px;
    }
  }
  .sns--list.flex li:not(:last-child) {
    margin-right: 32px;
  }
  .sns--list a {
    display: flex;
    justify-content: center;
    align-items: center;
    @include rect(24);
  }
  .sns--list a:hover {
    opacity: .7;
  }
  .sns--list svg {
    fill: var(--bg-off_wht, #f7f7f7);
    width: 24px;
  }

  /* ---fnav --- */
  .fnav {
    .gnav2--menu {
      @include mq(sp, min, ps) {
        /* display: grid;
        grid-template-columns: repeat(2,1fr);
        column-gap: perc(40,840); */
        border-top: 0;
      }
    }
    .gnav2--menu > li:nth-child(1) {
      /* grid-row: 1/3; */
      @include mq(sp, min, ps) {
        border-top: 1px solid rgba(215,212,191,.2);
      }
    }
    /* .gnav2--menu > li:nth-child(2) {
      grid-row: 3/5;
    } */
    .gnav2--menu > li:nth-child(3) {
      @include mq(sp, min, ps) {
        border-top: 1px solid rgba(215,212,191,.2);
      }
    }
    /* .gnav2--menu > li:nth-child(-n+2) {
      grid-column: 1/2;
    }
    .gnav2--menu > li:nth-last-child(-n+4) {
      grid-column: 2/3;
    } */
    .gnav2--menu .font-en {
      font-size: 1.125rem; /* 18px */
      width: 160px;
      line-height: 1.8;
    }
    .gnav2--menu > li > a,
    .head_sub_nav2--wrap {
      width: calc(100% - 160px);
    }
    .gnav2--menu > li > a {
      font-size: 1rem; /* 16px */
    }
    .head_sub_nav2 a {
      font-size: .813rem; /* 13px */
      padding-top: .1em;
      padding-bottom: .1em;
    }
  }

/* footer_btm
*************************************************** */
  .footer_btm {
    padding-bottom: 80px;
    @include mq(sp) {
      padding-bottom: 40px;
    }
  }
  .footer .gnav2--copyright {
    @include mq(sp) {
      align-items: flex-start;
    }
  }
  .footer .gnav2--copyright > *:not(:last-child) {
    @include mq(sp, min, ps) {
      margin-right: 1.5em;
    }
  }
  .footer .privacy {
    @include mq(sp) {
      align-self: flex-start;
    }
  }
  .footer_btm--ttl {
    @include mq(sp) {
      font-size: sprem(18);
      margin-top: 40px;
    }
  }
  .footer .copyright {
    @include mq(sp) {
      margin-top: 24px;
    }
  }
  .pbl a,
  .pbl img {
    display: block;
  }
  .pbl a {
    opacity: .5;
    &:hover {
      opacity: 1;
    }
  }
  .footer .deco_wrap {
    @include mq(1324) {
      width: 100%;
    }
  }
  .footer .deco_wrap img {
    bottom: 0;
    left: 407px;
    @include mq(1324) {
      left: perc(104,1324);
    }
    @include mq(sp) {
      left: perc(247,414);
      transform: scaleX(-1);
    }
  }