@use '../abstracts' as *;

/* ##############################################################################

    SINGLE

############################################################################## */

  /* --- アイキャッチ --- */
  .eyecatch {
    margin-bottom: 2rem;
  }

  /* --- 目次 --- */
  .ez-toc-debug-messages {
    display: none;
  }
  div#ez-toc-container {
    padding: 24px;
    background: #fafafa;
  }
  .ez-toc-title-container {
    margin-bottom: 10px;
  }
  div#ez-toc-container p.ez-toc-title {
    color: #000;
    font-weight: bold;
    font-size: 1.25rem;
  }
  #ez-toc-container.counter-hierarchy ul {
    font-size: 1.0625rem;
  }
  #ez-toc-container.counter-hierarchy ul li {
    margin-top: 8px;
  }
  #ez-toc-container.counter-hierarchy ul ul {
    margin-left: 1rem;
  }
  #ez-toc-container.counter-hierarchy ul ul a {
    position: relative;
    display: inline-block;
    padding-left: 1rem;
  }
  #ez-toc-container.counter-hierarchy ul ul a::before {
    content: "";
    position: absolute;
    top: .65rem;
    left: 0;
    width: .6rem;
    height: 0;
    border-bottom: 1px solid;
  }

/* pass
**************************************** */
  #pass p {
    margin-bottom: 1em;
  }
  #pass input[name="post_password"] {
    padding: 5px;
    border: solid 1px #aaa;
    outline: none;
  }
  #pass input[type="submit"] {
    padding: 4px 16px;
    letter-spacing: .15em;
    background-color: #ddd;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: var(--transit-default, all .4s ease-out);
  }
  #pass input[type="submit"]:hover {
    opacity: .7;
  }

/* blog--share
**************************************** */
  .blog--share_ttl {
    display: table;
    margin-left: auto;
    margin-right: auto;
    padding-left: .8em;
    padding-right: .8em;
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 2px;
      height: 1em;
      background-color: var(--clr-body, #333);
    }
    &::before {
      left: 0;
      transform: rotate(-30deg);
    }
    &::after {
      right: 0;
      transform: rotate(30deg);
    }
  }
  .blog--share_list {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: rem(8);
    @include mq(sp) {
      grid-template-columns: repeat(2,1fr);
    }
  }
  .blog--share_list a {
    display: flex;
    @include center-flex;
    height: rem(40);
    padding: .5em;
    line-height: 1;
    font-size: .75rem; /* 12px */
    font-weight: bold;
    background-color: var(--clr-wht, #fff);
    border-radius: rem(8);
  }
  .blog--share_list li {
    &.twitter a { color: #2BA1F2; }
    &.facebook a { color: #2477F2; }
    &.line a { color: #27C754; }
    &.pocket a { color: #EF4056; }
    &.linkedin a { color: #2867B2; }
    &.hatena a { color: #29A4DE; }
  }
  .blog--share_list img {
    margin-right: 10px;
  }
  .blog--share_list a:hover {
    opacity: .5;
  }

/* blog--related
**************************************** */
  .posts_scroll {
    overflow: auto;
    padding-bottom: 2rem;
  }
  .posts_scroll .post {
    display: block;
    width: 46%;
    flex-shrink: 0;
    @include mq(sp) {
      width: 70%;
    }
  }
  .posts_scroll .post:not(:last-child) {
    margin-right: 1rem;
  }
  .posts_scroll .post--img {
    margin-bottom: 1rem;
  }
  .posts_scroll .post--img::before {
    padding-top: 66.666%;
  }
  .posts_scroll .post--txt {
    overflow: hidden; 
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; 
  }
  .posts_scroll::-webkit-scrollbar {
    height: 6px;
  }
  .posts_scroll::-webkit-scrollbar-thumb {
    background-color: #999;
  }
  .posts_scroll::-webkit-scrollbar-track-piece {
    background-color: #eee;
  }

/* wp-pagenavi
**************************************** */
  .wp-pagenavi {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
    @include mq(sp) {
      margin-top: 24px;
    }
  }
  .wp-pagenavi .pages {
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: 1em;
  }
  .wp-pagenavi a {
    position: relative;
    padding: .25em 1em;
    background-color: var(--clr-body, #333);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include mq(sp) {
      padding: .15em 1em;
    }
  }
  .wp-pagenavi a:hover {
    opacity: .7;
  }
  .wp-pagenavi a {
    color: #fff;
  }
  .wp-pagenavi > *:not(.pages) {
    max-width: calc(100% / 2.6);
  }
  .archive .wp-pagenavi > *:not(.pages) {
    display: flex;
    justify-content: center;
    align-items: center;
    @include rect(40);
    @include mq(sp) {
      @include rect(32);
    }
  }
  .wp-pagenavi-single a[rel="prev"],
  .wp-pagenavi-single a[rel="next"] {
    width: 40px;
  }
  .wp-pagenavi > *:not(.pages) {
    margin-bottom: 4px;
  }
  .wp-pagenavi > *:not(.pages):not(:last-child) {
    margin-right: 4px;
  }
  .wp-pagenavi .extend {
    width: 24px !important;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-top: 2px solid;
    border-right: 2px solid;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    width: 8px;
    height: 8px;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .first::after {
    transform: rotate(-135deg);
  }
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .last::after {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="prev"]::before {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="next"]::before {
    transform: rotate(-135deg);
  }
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before {
    left: -6px;
    @include mq(sp) {
      left: -5px;
    }
  }
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    right: -6px;
    @include mq(sp) {
      right: -5px;
    }
  }

  @include mq(sp) {
    .wp-pagenavi-single a[rel="prev"],
    .wp-pagenavi-single a[rel="next"] {
      width: 32px;
    }
  }

/* works
**************************************** */
  .works--info .eyecatch {
    border-radius: rem(20);
    overflow: hidden;
    &::before {
      padding-top: perc(560,1200);
    }
  }
  .works--blc {
    &:not(:last-child) {
      margin-bottom: rem(80);
    }
  }
  .works--blc-sm {
    &:not(:last-child) {
      margin-bottom: rem(40);
    }
  }
  .works--before_after {
    display: grid;
    @include mq(sp, min, ps) {
      grid-template-columns: repeat(2,1fr);
      gap: clamp(32px, calc(40/1350*100vw), 40px) perc(48,1200);
      align-items: center;
    }
    @include mq(sp) {
      grid-template-columns: 1fr;
      gap: 24px;
    }
    &:not(:first-child) {
      padding-top: rem(48);
    }
    &:not(:last-child) {
      padding-bottom: rem(48);
      border-bottom: 2px dashed #ccc;
    }
  }
  .works--img {
    border-radius: rem(20);
    overflow: hidden;
    &::before {
      padding-top: perc(3,4);
    }
    .label {
      top: perc(16,432);
      left: perc(16,576);
      font-size: clamp(rem(14), calc(18/1350*100vw), rem(18));
      padding: .2em 1em;
      z-index: 1;
      border-radius: rem(4);
      @include mq(sp) {
        top: perc(32,432);
        left: perc(32,576);
      }
    }
    .label-before {
      background-color: var(--clr-body, #333);
    }
    .label-after {
      background-color: var(--clr-main, #1D7FB7);
    }
  }
  .works--content:not(:nth-child(2)) {
    @include mq(sp, min, ps) {
      grid-column: 1/3;
    }
  }
  .mce-content-body .box-works {
    position: relative;
    padding: rem(124) rem(80) rem(64);
    font-size: 1rem; /* 16px */
    max-width: 880px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    @include mq(sp) {
      padding: rem(96) rem(30) rem(30);
    }
    &:not(:first-child) {
      margin-top: rem(-148);
      @include mq(sp) {
        margin-top: rem(-112);
      }
    }
  }
  .works--illust {
    bottom: -48px;
    right: -64px;
    margin: 0 !important;
    @include mq(sp) {
      width: 96px;
    }
  }
  .works--related {
    margin-top: rem(80);
  }
  .works--point {
    border-bottom: 2px dashed #ccc;
    padding: perc(40,1200);
    @include mq(sp) {
      padding: rem(24) 0;
      &:first-child {
        border-top: 2px dashed #ccc;
      }
    }
  }
  .flex-col2 {
    @include mq(sp, min, ps) {
      .works--point:nth-child(2n+1):nth-last-child(-n+2),
      .works--point:nth-child(2n+1):nth-last-child(-n+2) ~ div {
        border-bottom: 0;
      }
      .works--point:not(:nth-child(2n)) {
        border-right: 2px dashed #ccc;
      }
    }
  }
  .flex-col3 {
    @include mq(sp, min, ps) {
      .works--point:nth-child(3n+1):nth-last-child(-n+3),
      .works--point:nth-child(3n+1):nth-last-child(-n+3) ~ div {
        border-bottom: 0;
      }
      .works--point:not(:nth-child(3n)) {
        border-right: 2px dashed #ccc;
      }
    }
  }
  .works--point_ttl {
    line-height: 1;
    margin-bottom: 1em;
  }
  .works--point_ttl .num {
    margin-left: .2em;
  }