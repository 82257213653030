@use '../abstracts/' as *;

/* home_feature
********************************************** */
  .home_feature--blc {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    @include mq(sp, min, ps) {
      display: grid;
      grid-template-columns: perc(762,1400) auto;
      column-gap: perc(40,1400);
      align-items: center;
    }
    @include mq(sp) {
      &>*:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  .home_feature--blc .imgarea {
    @include mq(sp) {
      width: perc(414,350);
      max-width: perc(414,350);
      margin-left: perc(-32,350);
    }
  }