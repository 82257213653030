@use '../abstracts' as *;

/* lps_parts--slide
********************************************** */
  .lps_parts--slide .img img {
    display: inline-block;
  }
  .lps_parts--slide .img + .text {
    margin-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .lps_parts--slide .slick-list {
    margin-bottom: 2rem;
  }
  .slick-arrow {
    position: absolute;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 100%;
    text-decoration: none;
    color: var(--clr-main, #1D7FB7);
    @include mq(med) {
      width: 56px;
    }
    @include mq(sp) {
      width: 40px;
    }
    &::after {
      content: '';
      display: block;
      width: 30px;
      height: 30px;
      transform: rotate(45deg);
      @include mq(med) {
        width: 20px;
        height: 20px;
      }
      @include mq(sp) {
        width: 16px;
        height: 16px;
      }
    }
  }
  .slick-prev { 
    left: -60px;
    padding-left: 1.5%;
    @include mq(med) {
      left: 0;
    }
    &::after {
      border-bottom: 2px solid;
      border-left: 2px solid;
    }
  }
  .slick-next { 
    right: -60px;
    padding-right: 1.5%; 
    @include mq(med) {
      right: 0;
    }
    &::after {
      border-top: 2px solid;
      border-right: 2px solid;
    }
  }
  .slick-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .slick-dots li {
    display: block;
    @include rect(12);
    background-color: #ddd;
    border-radius: 50%;
    cursor: pointer;
    transition: var(--transit-default, all .4s ease-out);
    margin: rem(6);
    @include mq(sp) {
      @include rect(10);
    }
  }
  .slick-dots li.slick-active {
    background-color: var(--clr-main, #1D7FB7);
  }
  .slick-dots li:hover {
    opacity: .7;
  }