@use './variables' as *;
@use 'sass:math';

/// メディアクエリーを簡単に呼び出す
/// @param {文字列｜数字} $mq-breakpoint (デフォルト：SP)
///    ターゲットのブレーキポイント。数字を入れたら、その値を使う
/// @param {文字列} $mq-width (デフォルト：max)
///    {$mq-width}-widthのブレーキポイントを生成。maxまたmin入力可能。minを使う場合、入れた値に１pxが足される
/// @param {文字列} $mq-type (デフォルト：screen)
///    媒体ターゲット。ps (print, screen) と p (print) 入れられます。
/// @param {マップ} $mq-breakpoints
///    _variables の $em-breakpointsのマップ。デフォルトで、em単位を使うバージョンですが、 $px-breakpoints もあります。（未対応）
/// @return {文字列} @media {$mq-type} and ({$mq-width}-width:em) {}

@mixin mq($mq-breakpoint: 'sp', $mq-width: 'max', $mq-type: 'screen', $mq-breakpoints: $em-breakpoints) {
  // $mq-breakpointsに$breakpointsを入れる
  @if map-has-key($mq-breakpoints, $mq-breakpoint) {
      $mq-breakpoint: map-get($mq-breakpoints, $mq-breakpoint);
  }
  @else {
      $mq-breakpoint: math.div($mq-breakpoint, 16) + 'em';
  }
  @if $mq-type == 'ps' {
    $mq-type: 'print, screen';
  }
  @if $mq-type == 'p' {
    $mq-type: 'print';
  }
  @if $mq-type == 's' {
    $mq-type: 'screen';
  }
  // min-widthの場合１pxを追加
  @if $mq-width == 'min' {
    @media #{$mq-type} and (#{$mq-width}-width: #{$mq-breakpoint + 0.0625}) {
        @content;
    }
  } @else {
    @media #{$mq-type} and (#{$mq-width}-width: #{$mq-breakpoint}) {
      @content;
    }
  }
}

/// 1行で左と右のマージンを設定
/// @param {数字} $margin-size
///   値入れなければ、autoになります。入れると、pxで左と右のマージんがつけられます
@mixin auto-margin($margin-size: 'auto') {
  @if $margin-size != 'auto' {
    $margin-size: #{$margin-size + 'px'};
  }
  margin-left: #{$margin-size};
  margin-right: #{$margin-size};
}

// flexで中央センター。因数なし
@mixin center-flex {
  justify-content: center;
  align-items: center;
}

/// padding を早く出す。単位無し。
/// @param {数字＋単位} $padding-top
/// @param {数字+単位} $padding-left
///   上と左のパーディング。適切なミックスインで、デフォルトの値になる
@mixin p-tb($padding-top, $padding-btm: $padding-top) {
  padding-top: #{$padding-top};
  padding-bottom: #{$padding-btm};
}
@mixin p-lr($padding-left, $padding-right: $padding-left) {
  padding-left: #{$padding-left};
  padding-right: #{$padding-right};
}
@mixin p-all ($padding-top, $prgt: $padding-top, $pbtm: $padding-top, $plft: $padding-top) {
  padding-top: #{$padding-top};
  padding-right: #{$prgt};
  padding-bottom: #{$pbtm};
  padding-left: #{$plft};
}

@mixin resp_rem {
  // ToDo
  //　REMを使ってそれぞれの端末に対応しているフォントサイズ
}

// @mixin font-resp($size, $screens...)  {
//   @if length($screens) == 0 {
//     $screens: 'sp';
//     font-size: math.div($size, 16);
//     @media screen and (max-width: 767px) {
//       font-size: math.div(($i - 1), 14) + rem ;
//     }
//   }
//   @else {
//     @each $screen in $screens {

//     }
//   }

// }

//　四辺形を生成する
/// @param {数字} $width
///   幅の値
/// @param {数字(任意)} $height
///   高さの値。何も入力されていない場合、$widthを使い正方形作る、
/// @return width: $width; height: $height;
@mixin rect($width, $height: $width) {
  width: $width + px;
  height: $height + px;
}

@mixin border-tb($size: '1px', $color: $clr-main, $type: 'solid'  ) {
  border-top: #{$size} #{$type} #{$color};
  border-bottom: #{$size} #{$type} #{$color};
}
