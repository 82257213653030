@use '../abstracts/' as *;

/* layout-wrap
********************************************** */
  .layout-wrap.section_pdg {
    padding-top: 248px;
    @include mq(sp) {
      padding-top: spvw(45);
      padding-bottom: spvw(60);
    }
  }
  .layout-wrap--blc::before {
    content: '';
    position: absolute;
    top: -128px;
    right: 0;
    width: perc(1600,1920);
    height: 640px;
    background-color: var(--clr-main, #1D7FB7);
    z-index: -1;
    @include mq(sp, min, ps) {
      border-radius: rem(20) 0 0 rem(20);
    }
    @include mq(sp) {
      top: spvw(-45);
      width: 100%;
    }
  }
  .layout-wrap-reverse .layout-wrap--blc::before {
    left: 0;
    right: inherit;
    border-radius: 0 rem(20) rem(20) 0;
  }
  .layout-wrap--blc .imgarea {
    overflow: hidden;
    width: perc(1200,1920);
    height: 720px;
    border-radius: 0 rem(20) rem(20) 0;
    @include mq(sp, min, ps) {
      position: absolute;
      top: -59px;
      left: 0;
      z-index: -1;
    }
    @include mq(sp) {
      width: perc(394,414);
      height: spvw(280);
    }
  }
  .layout-wrap-reverse .layout-wrap--blc .imgarea {
    @include mq(sp, min, ps) {
      left: inherit;
      right: 0;
      border-radius: rem(20) 0 0 rem(20);
    }
  }
  .layout-wrap--blc .txtarea {
    padding: 132px perc(156,1920) 142px;
    background-color: var(--clr-wht, #ffff);
    width: 880px;
    border-radius: rem(20);
    margin-left: auto;
    @include mq(sp, min, ps) {
      position: relative;
      min-height: 720px;
      margin-right: perc(123,1920);
      @include mq(pc) {
        width: 769px;
      }
    }
    @include mq(sp) {
      width: perc(399,414);
      padding: 48px perc(32,414) 66px;
      margin-top: -20px;
      border-radius: rem(20) 0 0 rem(20);
    }
  }
  .layout-wrap-reverse .layout-wrap--blc .txtarea {
    @include mq(sp, min, ps) {
      margin-right: auto;
      margin-left: perc(123,1920);
    }
  }
  .layout-wrap .deco {
    bottom: -44px;
    right: -48px;
    @include mq(sp) {
      bottom: -36px;
      left: -2px;
      right: inherit;
      width: 239px;
      height: auto;
    }
  }
  .layout-wrap-reverse .deco {
    @include mq(sp, min, ps) {
      left: -48px;
      right: inherit;
    }
  }
  .layout-wrap .en {
    top: 0;
    line-height: 1;
    font-weight: bold;
    @include mq(sp, min, ps) {
      left: -96px;
      transform-origin: left top;
      transform: rotate(-90deg) translateX(-100%);
    }
    @include mq(sp) {
      top: spvw(-26);
      right: perc(11,414);
      font-size: spvw(48);
    }
  }
  .layout-wrap-reverse .en {
    @include mq(sp, min, ps) {
      left: inherit;
      right: -96px;
      transform-origin: right top;
      transform: rotate(90deg) translateX(100%);
    }
  }
  .layout-wrap--slide {
    bottom: -28px;
    opacity: .75;
    @include mq(sp) {
      bottom: spvw(-72);
    }
  }