@use "../abstracts" as *;

/* lps_parts--faq
********************************************** */
.lps_parts--faq .faq_item {  
  counter-increment: number;
  &:not(:last-child) {
    margin-bottom: rem(40);
  }
}
.lps_parts--faq .ttl-03,
.lps_parts--faq .mce-content-body {
  padding: rem(24) rem(48);
  @include mq(sp) {
    padding: rem(24);
  }
}
.lps_parts--faq .ttl-03 {
  font-size: 1.125rem; /* 18px */
  background-color: #E2E1DA;
  margin-bottom: 0 !important;
  &::after {
    content: 'Q-'counter(number,decimal-leading-zero);
    position: absolute;
    top: -.5em;
    right: rem(20);
    display: block;
    color: var(--clr-main, #1D7FB7);
    font-family: var(--font-en, "Montserrat", sans-serif);
    font-size: 2rem; /* 32px */
    line-height: 1;
  }
}
.lps_parts--faq .mce-content-body {
  position: relative;
  font-size: .938rem; /* 15px */
  background-color: var(--clr-wht, #fff);
}
.lps_parts--faq .mce-content-body .editor-ttl2 {
  margin-top: 0;
}
.lps_parts--faq .text img {
  max-width: 280px;
  margin-top: 0;
  margin-right: rem(30);
  overflow: hidden;
  @include mq(sp) {
      float: none !important;
      margin-top: 2px;
      margin-left: 0;
      margin-right: auto;
      margin-bottom: 8px !important;
      border-radius: 8px;
  }
}
