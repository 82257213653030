@use '../abstracts/' as *;

/* home_deco
********************************************** */
  .home_deco {
    z-index: 1;
  }
  .deco_slide {
    left: 0;
    z-index: -1;
  }
  .home_deco--slide {
    top: perc(40,330);
  }
  .deco_catch {
    animation: loop_slide 30s infinite linear 1s both;
    letter-spacing: .04em;
    white-space: nowrap;
    padding-left: .25em;
    padding-right: .25em;
    font-weight: bold;
    line-height: 1;
    font-size: clamp(rem(183), calc(240/1416*100vw), rem(240));
    @include mq(sp) {
      font-size: spvw(120);
    }
  }
  .home_deco--blc.inner {
    display: grid;
    max-width: 1260px;
    @include mq(sp) {
      width: perc(532,414);
      max-width: perc(532,414);
      margin-left: perc(-69,414);
    }
    &.flex-j-end {
      grid-template-columns: auto perc(685,1260);
      @include mq(sp) {
        grid-template-columns: auto perc(342,532);
      }
    }
    &.flex-j-ctr {
      grid-template-columns: perc(453,1260);
      @include mq(sp) {
        grid-template-columns: perc(180,350);
      }
    }
  }
  .home_deco--txt {
    color: #D5D5D5;
    letter-spacing: .04em;
    white-space: nowrap;
    font-size: clamp(rem(15), calc(20/1416*100vw), rem(20));
    @include mq(sp, min, ps) {
      padding-bottom: 1em;
      margin-right: perc(-44,471);
    }
    @include mq(sp) {
      font-size: spvw(8);
      line-height: 1.2;
      margin-left: perc(100,532);
      transform: scale(.9);
      transform-origin: left;
    }
  }