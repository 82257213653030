@use '../abstracts' as * ;

/* ##############################################################################

    COOKIE

############################################################################## */
  #cookie-notice {
    font-family: var(--font-primary, YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif);
    line-height: var(--line-height-default, 1.8);
    font-weight: 500;
    font-feature-settings: "palt";
    letter-spacing: var(--ltr-space-default, .08em);
    @include mq(sp, min, ps) {
      bottom: 1rem;
      left: inherit;
      right: 1rem;
      width: 100%;
      min-width: inherit;
      max-width: 420px;
      box-shadow: 0 0 1rem rgba($color: #333, $alpha: .1);
    }
  }
  .cookie-notice-container {
    padding: 1.5rem 2.5rem;
    text-align: left;
  }
  .cn-text-container {
    margin: 0;
  }
  .cn-buttons-container {
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
  .cn-buttons-container a {
    display: block;
    flex: 1;
    &:not(:last-child) {
      margin-right: .5rem;
    }
    &:hover {
      opacity: .7;
    }
  }
  #cookie-notice .cn-button:not(.cn-button-custom) {
    border-radius: 0;
    font-family: var(--font-en, --font-jp, 'Montserrat', sans-serif);
    font-size: 1rem;
    padding: .6rem 1rem .4rem 1rem;
    margin-left: 0;
    color: var(--clr-wht, #fff);
  }
  .cn-close-icon {
    top: 0;
    right: 0;
    margin-top: 0;
    width: 1.75rem;
    height: 1.75rem;
    padding: 0;
    background-color: var(--clr-wht, #fff);
    transition: var(--transit-default, all .4s ease-out);
    &::before,
    &::after {
      height: 1.25rem;
      width: .125rem; /* 2px */
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background-color: var(--clr-body, #333);
    }
  }