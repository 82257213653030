@use '../abstracts' as *;
@use 'sass:math';

/* --- font-size --- */
// フォントサイズを8pxから140pxまでREMで生成
@for $i from 8 to 140 {
  .fz-#{$i} { font-size: math.div($i, 16) + rem}
}
// フォンt-サイズをREM:14引く1px
// これは古い .fz-{数字}のクラスをマネする
@include mq(sp) {
  @for $i from 8 to 140 {
    .fz-#{$i} { font-size: math.div(($i - 1), 14) + rem}
  }
}

/* txt
**************************************** */
.txt-lg { font-size: 1.125rem; /* 18px */ }
.txt-sm { font-size: .875rem; /* 14px */ }
.txt-wh,
.txt-wh * { color: var(--clr-wht, #fff); }
.txt-off_wh,
.txt-off_wh * { color: var(--clr-off_wht, #f7f7f7); }
.txt-main,
.txt-main * { color: var(--clr-main, #1D7FB7); }
.txt-sub,
.txt-sub * { color: var(--clr-sub, #eee); }

/* --- font --- */
.font-jp {
  font-family: var(--font-jp, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
}
.font-en {
  font-family: var(--font-en, --font-jp, 'Montserrat', sans-serif);
  &:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
    font-weight: 600;
  }
}
.font-num {
  font-family: var(--font-num, --font-jp, 'Montserrat', sans-serif);
}

/* txt
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}
.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}
.txt-tate {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-font-feature-settings: 'pkna';
  font-feature-settings: 'pkna';
}
.txt-tate * {
  font-feature-settings: normal;
}

@include mq(sp) {
  .txt-lg { font-size: 1.071rem; /* 15px */ }
  .txt-sm { font-size: .929rem; /* 13px */ }
  .txt-ctr-pc,
  .txt-rgt-pc { text-align: left; }
}