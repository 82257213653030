@use '../abstracts/' as *;

/* home_department
********************************************** */
  .home_department.section_pdg {
    padding-bottom: 0;
  }
  .home_department .ttl-03 {
    @include mq(sp) {
      font-size: .929rem; /* 13px */
    }
  }
  .home_department--wrap {
    --wid: 900px;
    --hgt: 835px;
    height: 100vh;
    @include mq(1560) {
      --wid: calc(800/1560*100vw);
      --hgt: calc(742/1560*100vw);
    }
    @include mq(med) {
      --wid: 554px;
      --hgt: 514px;
    }
    @include mq(sp) {
      --wid: 476px;
      --hgt: 571px;
    }
  }
  .home_department--blcs {
    top: 0;
    left: 0;
  }
  .home_department--blc {
    position: absolute;
    width: 90%;
    max-width: 1489px;
    height: 100vh;
    left: perc(149,1920);
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: all .4s cubic-bezier(.5,0,0,1);
    &.current {
      opacity: 1;
      pointer-events: auto;
      transition-delay: .4s;
    }
    @include mq(sp, min, ps) {
      display: grid;
      grid-template-columns: var(--wid) auto;
      gap: perc(107,1622);
      @include mq(1560) {
        left: perc(78,1560);
        gap: perc(64,1560);
      }
    }
    @include mq(sp) {
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: var(--wid);
      height: var(--hgt);
    }
  }
  .home_department--scrolls {
    top: 317px;
    left: perc(223,1920);
    width: 45px;
    height: 128px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    font-size: clamp(rem(10), calc(14/1560*100vw), rem(14));
    z-index: 1;
    @include mq(1560) {
      left: perc(198,1920);
      width: calc(40/1560*100vw);
      height: calc(114/1560*100vw);
    }
    @include mq(med) {
      left: 111px;
      width: 27.6px;
      height: 79px;
    }
    @include mq(sp) {
      top: calc(50% - perc(125,571));
      left: 27px;
      transform: translateY(-50%);
      width: 34px;
      height: 96px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: clamp(4px, calc(6/1560*100vw), 6px);
      width: 1px;
      height: 100%;
      background-color: #ccc;
      @include mq(sp) {
        left: 4px;
      }
    }
  }
  .home_department--scroll {
    position: relative;
    padding-left: clamp(18px, calc(26/1560*100vw), 26px);
    line-height: 1;
    font-weight: bold;
    color: rgba($color: #636363, $alpha: .5);
    transition: all .4s ease-out;
    @include mq(sp) {
      padding-left: 20px;
    }
    &:not(:last-child) {
      margin-bottom: clamp(39px, calc(56/1560*100vw), 56px);
      @include mq(sp) {
        margin-bottom: 40px;
      }
    }
    &.current {
      color: var(--clr-main, #1D7FB7);
    }
    &::before {
      content: '';
      position: absolute;
      top: .5em;
      left: 0;
      width: clamp(8.3px, calc(12/1560*100vw), 12px);
      height: 2px;
      background-color: var(--clr-main, #1D7FB7);
      opacity: 0;
      transition: all .4s ease-out;
      @include mq(sp) {
        width: 9px;
      }
    }
    &.current::before {
      opacity: 1;
    }
  }
  .home_department--circles {
    top: calc((100vh - var(--hgt)) / 2);
    left: perc(149,1920);
    width: var(--wid);
    height: var(--hgt);
    @include mq(1560) {
      left: perc(78,1560);
    }
    @include mq(sp) {
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
  .home_department--circle {
    position: absolute;
    width: perc(240,900);
    background-color: rgba($color: #fff, $alpha: .5);
    border-radius: 50%;
    z-index: -1;
    transition: 1s all cubic-bezier(.5,0,0,1);
    &::after {
      content: '';
      position: absolute;
      top: perc(25,650);
      left: perc(25,650);
      width: perc(600,650);
      height: perc(600,650);
      background-color: var(--clr-wht, #fff);
      border-radius: 50%;
      opacity: 0;
      transition: 1s all cubic-bezier(.5,0,0,1);
    }
    &.current-0 {
      &:nth-child(1) {
        top: perc(56,835);
        left: perc(250,900);
        width: perc(650,900);
        @include mq(sp) {
          top: 0;
          left: perc(49,476);
          width: perc(427,476);
        }
        &::after {
          opacity: 1;
        }
      }
      &:nth-child(2) {
        top: perc(595,835);
        left: perc(189,900);
        @include mq(sp) {
          top: perc(435,571);
          left: perc(133,476);
        }
      }
      &:nth-child(3) {
        top: perc(373,835);
        left: 0;
        @include mq(sp) {
          top: perc(368,571);
        }
      }
      /* 要素数が2個の2個目 */
      &:first-of-type:nth-last-of-type(2):nth-of-type(2),
      &:first-of-type:nth-last-of-type(2) ~ .home_department--circle:nth-of-type(2) {
        top: perc(565,835);
        left: perc(72,900);
        @include mq(sp) {
          top: perc(368,571);
        }
      }
    }
    &.current-1 {
      top: perc(595,835);
      left: perc(189,900);
      @include mq(sp) {
        top: perc(435,571);
        left: perc(133,476);
      }
      &:nth-child(2) {
        top: perc(373,835);
        left: 0;
        @include mq(sp) {
          top: perc(368,571);
          left: 0;
        }
      }
      &:nth-child(3) {
        top: perc(56,835);
        left: perc(250,900);
        width: perc(650,900);
        @include mq(sp) {
          top: 0;
          left: perc(49,476);
          width: perc(427,476);
        }
        &::after {
          opacity: 1;
        }
      }
      /* 要素数が2個の1個目 */
      &:first-of-type:nth-last-of-type(2):nth-of-type(1),
      &:first-of-type:nth-last-of-type(2) ~ .home_department--circle:nth-of-type(1) {
        top: perc(565,835);
        left: perc(72,900);
        @include mq(sp) {
          top: perc(368,571);
        }
      }
      /* 要素数が2個の2個目 */
      &:first-of-type:nth-last-of-type(2):nth-of-type(2),
      &:first-of-type:nth-last-of-type(2) ~ .home_department--circle:nth-of-type(2) {
        top: perc(56,835);
        left: perc(250,900);
        width: perc(650,900);
        @include mq(sp) {
          top: 0;
          left: perc(49,476);
          width: perc(427,476);
        }
        &::after {
          opacity: 1;
        }
      }
    }
    &.current-2,
    &.current-4 {
      top: perc(373,835);
      left: 0;
      @include mq(sp) {
        top: perc(368,571);
      }
      &:nth-child(2) {
        top: perc(56,835);
        left: perc(250,900);
        width: perc(650,900);
        @include mq(sp) {
          left: perc(49,476);
          width: perc(427,476);
        }
        &::after {
          opacity: 1;
        }
      }
      &:nth-child(3) {
        top: perc(595,835);
        left: perc(189,900);
        @include mq(sp) {
          top: perc(435,571);
          left: perc(133,476);
        }
      }
    }
    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }
  .home_department--illust {
    bottom: perc(-4,835);
    right: perc(42,900);
    width: perc(158,900);
    height: auto;
    @include mq(sp) {
      bottom: 22px;
      right: 36px;
      width: 101px;
    }
  }
  .home_department--blc .imgarea {
    position: relative;
    height: var(--hgt);
    padding-top: perc(128,900);
    padding-left: perc(250,900);
    padding-bottom: perc(129,900);
    @include mq(sp) {
      padding-top: perc(36,476);
      padding-left: perc(49,476);
      padding-bottom: perc(144,476);
    }
  }
  .home_department--blc .num {
    line-height: 1;
    font-weight: bold;
    letter-spacing: .08em;
    font-size: clamp(rem(39), calc(56/1560*100vw), rem(56));
    @include mq(sp) {
      font-size: 1.714rem; /* 24px */
    }
    &.fz-96 {
      font-size: clamp(rem(66), calc(96/1560*100vw), rem(96));
      @include mq(sp) {
        font-size: sprem(72);
      }
    }
  }
  .home_department--blc .imgarea > .num {
    top: perc(106,835);
    right: perc(60,900);
    @include mq(sp) {
      top: perc(18,571);
      right: perc(60,476);
    }
  }
  .home_department--blc .ttl-02 {
    font-size: clamp(rem(22), calc(32/1560*100vw), rem(32));
    @include mq(sp) {
      font-size: sprem(24);
    }
  }
  .home_department--ttl-imgarea .ttl-sub {
    color: var(--clr-body, #333);
    font-size: clamp(rem(10), calc(13/1560*100vw), rem(13));
    @include mq(sp) {
      font-size: sprem(8);
    }
    &.font-en {
      color: #D7D4BF;
      margin-bottom: 2.47em; /* 32px */
      line-height: 1.4;
      letter-spacing: .15em;
      @include mq(sp) {
        font-size: rem(8);
        transform: scale(.8);
        margin-bottom: 1.75em;
      }
    }
    &:not(:first-child) {
      margin-top: 0;
    }
    &::after {
      display: none;
    }
  }
  .home_department--list {
    display: table;
    margin-left: auto;
    margin-right: auto;
    font-size: clamp(rem(10), calc(14/1560*100vw), rem(14));
    @include mq(sp) {
      font-size: sprem(11);
    }
  }
  .home_department--list li {
    position: relative;
    padding-left: 2em;
    &::before {
      content: '';
      position: absolute;
      top: .9em;
      left: 0;
      width: .857em; /* 12px */
      height: 2px;
      background-color: var(--clr-main, #1D7FB7);
      @include mq(sp) {
        width: .81em; /* 9px */
      }
    }
  }
  .home_department--other {
    width: perc(240,900);
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-size: clamp(rem(12), calc(18/1560*100vw), rem(18));
    cursor: pointer;
    @include mq(sp) {
      font-size: sprem(12);
    }
    &:nth-of-type(1) {
      top: perc(373,835);
      left: 0;
      @include mq(sp) {
        top: perc(368,571);
      }
      .num {
        bottom: perc(13,240);
        left: perc(3,240);
        @include mq(sp) {
          bottom: perc(124,135);
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    &:nth-of-type(2) {
      left: perc(189,900);
      @include mq(sp, min, ps) {
        bottom: 0;
      }
      @include mq(sp) {
        top: perc(435,571);
        left: perc(133,476);
      }
      .num {
        bottom: perc(36,240);
        right: 0;
        @include mq(sp) {
          bottom: perc(20,135);
          right: perc(-8,135);
        }
      }
    }
    /* 要素数が1個 */
    &:first-of-type:nth-last-child(1) {
      top: perc(565,835);
      left: perc(72,900);
      @include mq(sp) {
        top: perc(368,571);
      }
    }
    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }
  .home_department--other .txt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .home_department--blc .txtarea {
    align-self: center;
    padding-bottom: 64px;
    font-size: clamp(rem(11), calc(16/1560*100vw), rem(16));
  }
  .home_department--blc .btn a {
    font-size: min(calc(15/1560*100vw), rem(15));
    @include mq(1560) {
      max-width: calc(280/1560*100vw);
    }
    @include mq(med) {
      max-width: 194px;
    }
  }
  .home_department--ttl-txtarea {
    display: grid;
    grid-template-columns: 4.188em auto; /* 134px */
    grid-template-rows: 1.75em auto;
    @include mq(sp) {
      grid-template-columns: 3.9em auto; /* 78px */
      font-size: 1.429rem !important; /* 20px */
    }
    &::after {
      content: '';
      display: block;
      width: 2.5rem;
      height: 2px;
      background-color: #008CD6;
      background-color: var(--clr-main, #1D7FB7);
      margin-top: 2rem;
      @include mq(sp) {
        width: 1.2em; /* 24px */
        margin-top: .5em;
      }
    }
  }
  .home_department--ttl-txtarea span {
    display: block;
  }
  .home_department--ttl-txtarea .num {
    grid-row: 1/3;
    text-indent: -.04em;
    display: flex;
    line-height: 1;
    align-items: center;
  }
  .home_department--ttl-txtarea span:not(.num) {
    grid-column: 2/3;
  }
  .home_department--ttl-txtarea .ttl-sub {
    @include mq(sp) {
      font-size: .8rem; /* 9px */
      transform: scale(.8);
      transform-origin: left top;
    }
    &:not(:first-child) {
      margin-top: 0;
    }
    &::after {
      display: none;
    }
  }

  @include mq(sp) {
    .home_department--arrow {
      position: absolute;
      top: 12px;
      right: 0;
      @include rect(24);
      background-color: var(--clr-main, #1D7FB7);
      border-radius: 50%;
      color: var(--clr-wht, #fff);
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 12%;
        margin: auto;
        @include rect(8);
        border-top: 1px solid;
        border-right: 1px solid;
        transform: rotate(45deg);
      }
    }
    .home_department--txtareas {
      width: perc(392,414);
      margin-bottom: 68px;
      margin-left: auto;
      margin-right: auto;
    }
    .home_department--txtareas .txtarea {
      padding: 30px perc(20,392);
      border-bottom: 1px solid #CBC9B1;
      &:first-child {
        border-top: 1px solid #CBC9B1;
      }
    }
  }