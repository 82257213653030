@use '../abstracts' as * ;

/* cursor
********************************************** */
  .cursor_wrap {
    position: fixed;
    transform: translate(-50%,-50%);
    width: 80px;
    height: 80px;
    margin-top: -40px;
    margin-left: -40px;
    border-radius: 50%;
    pointer-events: none;
    z-index: z(top);
    animation: rotate 10s linear infinite;
    @include mq(sp) {
      width: 60px;
      height: 60px;
      margin-top: -30px;
      margin-left: -30px;
    }
  }
  .cursor_wrap,
  .cursor_wrap svg {
    transition:
      left .05s linear,
      top .05s linear,
      width .7s cubic-bezier(.43, -.01, .15, .99),
      height .7s cubic-bezier(.43, -.01, .15, .99),
      margin .7s cubic-bezier(.43, -.01, .15, .99);
  }
  .cursor_wrap svg {
    width: 100%;
    height: 100%;
  }