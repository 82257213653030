@use '../abstracts' as *;

/* --- section_pdg --- */
.section_pdg {
  padding-top: 96px;
  padding-bottom: 96px;
  @include mq(sp) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
.section_pdg-sm {
  padding-top: 40px;
  padding-bottom: 40px;
  @include mq(sp) {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
.deco_wrap {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1920px;
  height: 100%;
  pointer-events: none;
}
.deco_wrap img {
  position: absolute;
}