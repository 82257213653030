@use '../abstracts' as *;

/* hero
********************************************** */
.hero {
  position: relative;
  height: 743px;
  @include mq(1560) {
    height: max(514px, calc(743/1560*100vw));
  }
  @include mq(sp) {
    height: spvw(640);
  }
}
.hero .header--txt {
  @include mq(med) {
    padding: spvw(24) perc(24,414) spvw(18);
  }
}
.hero_slide {
  animation: loop_slide 30s infinite linear 1s both;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  @include mq(1560) {
    padding-left: calc(16/1560*100vw);
    padding-right: calc(16/1560*100vw);
  }
  @include mq(sp) {
    padding-left: calc(20/767*100vw);
    padding-right: calc(20/767*100vw);
  }
}
.hero_slide--list {
  display: grid;
  grid-template-columns: 354px 236px 354px 236px 354px 742px;
  grid-template-rows: repeat(2,1fr);
  gap: 32px;
  @include mq(1560) {
    grid-template-columns: calc(354/1560*100vw) calc(236/1560*100vw) calc(354/1560*100vw) calc(236/1560*100vw) calc(354/1560*100vw) calc(742/1560*100vw);
    gap: max(22.1538px, calc(32/1560*100vw));
  }
  @include mq(med) {
    grid-template-columns: 245.062px 163.375px 245.062px 163.375px 245.062px 513.688px;
  }
  @include mq(sp) {
    grid-template-columns: calc(425/767*100vw) calc(283/767*100vw) calc(425/767*100vw) calc(283/767*100vw) calc(425/767*100vw) calc(890/767*100vw);
    gap: calc(40/767*100vw);
    height: spvw(560);
  }
}
.hero_slide--list li {
  position: relative;
  border-radius: rem(20);
  overflow: hidden;
  &:nth-child(odd):not(:nth-child(11)) {
    grid-row: 1/2;
  }
  &:nth-child(even) {
    grid-row: 2/3;
  }
  &:nth-child(11) {
    grid-row: 1/3;
  }
}
.hero_slide--list .font-en {
  top: 1.25rem;
  right: 1.5rem;
  white-space: nowrap;
  line-height: var(--line-height-hdr, 1.4);
  z-index: 2;
  @include mq(sp) {
    font-size: rem(10);
  }
}
.hero--logo {
  @include mq(1560) {
    margin-bottom: max(38px, calc(56/1560*100vw));
  }
}
.hero--logo img {
  width: 721px;
  height: auto;
  @include mq(1560) {
    width: max(499px, calc(721/1560*100vw));
  }
}
.hero--catch {
  position: absolute;
  top: 0;
  left: perc(127,1920);
  width: 952px;
  height: 100%;
  background-color: rgba($color: #EEEDE6, $alpha: .98);
  padding: 0 111px;
  @include mq(1560) {
    width: max(659px, calc(952/1560*100vw));
    padding: 0 max(78px, calc(111/1560*100vw));
  }
  @include mq(sp) {
    top: inherit;
    bottom: spvw(-16);
    left: 0;
    width: perc(336,414);
    height: spvw(200);
    padding: spvw(24) perc(24,336) 0;
    border-radius: 0 rem(20) 0 0;
  }
}
.hero--txtarea {
  @include mq(sp, min, ps) {
    padding-left: perc(68,952);
  }
}
.hero--ttl {
  line-height: 1.6;
  letter-spacing: .15em;
  margin-bottom: .8em;
  font-size: clamp(rem(24), calc(35/1560*100vw), rem(35));
  @include mq(sp) {
    font-size: spvw(20);
    margin-bottom: .5em;
  }
}
.hero--ttl span {
  background-image: linear-gradient(to right,#333,#333 3px,rgba(0,0,0,0) 3px,rgba(0,0,0,0) 100%);
  background-repeat: repeat-x;
  background-position: left 36% top;
  background-size: .9em 3px;
  padding-top: .2em;
}
.hero--txt {
  text-transform: uppercase;
  font-size: clamp(rem(10), calc(13/1560*100vw), rem(13));
  @include mq(sp) {
    font-size: spvw(8);
    letter-spacing: .04em;
    transform: scale(.95);
    transform-origin: left;
    white-space: nowrap;
  }
}
.hero--scroll {
  bottom: -24px;
  left: perc(109,952);
  width: 83px;
  height: 114px;
  font-size: clamp(rem(10), calc(13/1560*100vw), rem(13));
  @include mq(1560) {
    bottom: max(-16px, calc(-24/1560*100vw));
    width: max(57px, calc(83/1560*100vw));
    height: max(79px, calc(114/1560*100vw));
  }
}
.hero--scroll .bar {
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: var(--clr-main, #1D7FB7);
  transition: all .4s ease-out;
  animation: scroll_down 2s cubic-bezier(.5, -.1, .5, 1) infinite;
}