@use "sass:map";


  // SCSSのミックスインや関数が使える変数
  $clr-body: #636363;
  $clr-main: #008CD6;
  $fz-pc: 16;
  $fz-sp: 14;


// CSS変数
:root {
  // typography
  --ltr-space-default: .08em;
  --line-height-default: 2;
  --line-height-hdr: 1.6;
  --line-height-none: 1;

  // font-families
  --font-primary: "秀英角ゴシック銀 B", "Shuei KakuGo Gin B", YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif;
  --font-secondary: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif;
  --font-jp: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif;
  --font-en: 'p22-underground', sans-serif;
  --font-num: 'Montserrat', sans-serif;
  --font-noto-sans: 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

  // font-sizes
  --fz-primary: 1rem;
  --fz-half: .5rem;



  // text colors
  --clr-main: #008CD6;
  --clr-txt-sub: #eee;
  --clr-body: #636363;
  --clr-link: #636363;
  --clr-wht: #fff;
  --clr-blk: #000;

  // background colors
  --bg-main: #008CD6;
  --bg-sub: #eee;
  --bg-wht: #fff;
  --bg-off_wht: #EEEDE6;
  --bg-blk: #333;

  //button colors
  --btn-bg-main: #008CD6;
  --btn-bg-main-hvr: #1E465D;
  --btn-clr-main: #fff;
  --btn-clr--sec: #fff;

  --btn-clr-wht: #636363;
  --btn-bg-wht: #EEEDE6;
  --btn-clr-wht-hvr: #EEEDE6;
  --btn-bg-wht-hvr: #636363;


  // border colors
  --brd-main: #008CD6;

  // animation
  --transit-default: all 0.4s ease-out;


  // formy settings

}

// SASS Maps

$z-index: (
  "top": 9999,
  "header" : 9990,
  "aside" : 75,
  "middle" : 50,
  "main" : 1,
  "base" : 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160em, // 2560px
  lg: 120em, // 1920px
  pc: 85.375em, // 1366px
  med: 67.5em, // 1080px
  sp: 47.9375em, // 767px
  sm: 40em, // 640px
  xs: 34.375em, // 500px
  se: 21.25em, // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$colors: (
  'blue': (
    100: hsl(202, 73%, 82%),
    200: hsl(202, 73%, 72%),
    300: hsl(202, 73%, 62%),
    400: hsl(202, 73%, 52%),
    500: hsl(202, 73%, 42%),
    600: hsl(202, 73%, 32%),
  ),
  'red': (
    100: hsl(0, 100%, 80%),
    200: hsl(0, 100%, 70%),
    300: hsl(0, 100%, 60%),
    400: hsl(0, 100%, 50%),
    500: hsl(0, 100%, 40%),
    600: hsl(0, 100%, 30%),
  ),
  'green': (
    100: hsl(118, 100%, 80%),
    200: hsl(118, 100%, 70%),
    300: hsl(118, 100%, 60%),
    400: hsl(118, 100%, 50%),
    500: hsl(118, 100%, 40%),
    600: hsl(118, 100%, 30%),
  )
);