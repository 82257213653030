@use '../abstracts/' as *;

/* home_service
********************************************** */
  .home_service.section_pdg {
    @include mq(sp) {
      padding-bottom: 90px;
    }
  }
  .home_service .ttl-03 {
    @include mq(sp) {
      font-size: 1.143rem; /* 16px */
    }
  }
  .home_service--list {
    @include mq(sp) {
      width: perc(325,350);
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 40px;
    }
  }
  .home_service--list a,
  .home_service--list .txtarea {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .home_service--list a {
    padding: rem(24);
    border-radius: rem(20);
    @include mq(sp) {
      padding: rem(24) rem(16);
    }
    &:hover {
      background-color: #fff;
      box-shadow: 0 0 1rem rgba($color: #333, $alpha: .1);
    }
  }
  .home_service--list .imgarea {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 154px;
    margin-bottom: rem(30);
    @include mq(sp) {
      height: 104px;
      margin-bottom: rem(8);
    }
  }
  .home_service--list .imgarea img {
    width: auto;
    max-height: 100%;
    @include mq(sp) {
      transform: scale(.86);
    }
  }
  .home_service--txt {
    line-height: var(--line-height-hdr, 1.4);
    @include mq(sp) {
      font-size: .857rem; /* 12px */
    }
  }
  .home_service--more {
    margin-top: auto;
    @include mq(sp) {
      font-size: .857rem; /* 12px */
    }
  }