@use '../abstracts' as *;

/* lps_parts--flow
********************************************** */
  .lps_parts--flow .flow_item {
    position: relative;
    padding-left: rem(88);
    counter-increment: number;
    &:not(:last-child) {
      padding-bottom: rem(40);
    }
    &:not(:last-child)::before {
      content: '';
      position: absolute;
      top: rem(64);
      left: rem(40);
      display: block;
      width: 0;
      height: calc(100% - rem(80));
      border-right: 1px solid #ccc;
      @include mq(sp) {
        top: rem(56);
      }
    }
  }
  .lps_parts--flow .ttl-03 {
    font-size: 2rem; /* 32px */
    color: var(--clr-main, #1D7FB7);
    @include mq(sp) {
      font-size: 1.429rem; /* 20px */
    }
    &::after {
      content: counter(number,decimal-leading-zero);
      position: absolute;
      top: rem(-8);
      left: rem(-80);
      display: flex;
      @include center-flex;
      @include rect(64);
      border-radius: 50%;
      padding-top: 1%;
      text-align: center;
      color: #fff;
      background-color: var(--clr-main, #1D7FB7);
      font-family: var(--font-en, 'Montserrat', sans-serif);
      font-size: 1.625rem; /* 26px */
      @include mq(sp) {
        top: rem(-16);
        @include rect(56);
      }
    }
  }
  .lps_parts--flow .txt-wh .ttl-03 {
    color: var(--clr-wht, #fff);
  }
  .lps_parts--flow .flow_item.txt-wh:not(:last-child)::before {
    border-color: var(--clr-wht, #fff);
  }
  .lps_parts--flow .txt-wh .ttl-03::after {
    color: var(--clr-main, #1D7FB7);
    background-color: var(--clr-wht, #fff);
  }
  .lps_parts--flow .text img {
    max-width: 280px;
    margin-top: 0;
    margin-right: rem(30);
    overflow: hidden;
    @include mq(sp) {
      float: none !important;
      margin-left: 0;
      margin-right: auto;
      margin-bottom: 16px !important;
    }
  }
