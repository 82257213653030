@use '../abstracts' as *;

/* --- inner --- */
.inner {
  width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: perc(350,414);
  }
}
.inner-sm { max-width: 880px; }
.inner-xs { max-width: 720px; }
.inner-md { max-width: 1366px; }
.inner-md2 { max-width: 1360px; }
.inner-lg { max-width: 1440px; }
.inner-xl { max-width: 1724px; }