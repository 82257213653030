@use '../abstracts' as *;

/* btn
********************************************** */
  *:not(.flex) > .btn:not(:last-child) {
    margin-bottom: 8px;
  }
  .btn a {
    position: relative;
    z-index: 1;
    display: block;
    padding: .88em 2em;
    border-radius: 3em;
    width: 100%;
    max-width: 280px;
    color: var(--btn-clr-main, #fff);
    background-color: var(--btn-bg-main, #1D7FB7);
    font-size: .938rem; /* 15px */
    text-decoration: none;
    @include mq(sp) {
      font-size: 1.071rem; /* 15px */
    }
    &:hover {
      background-color: var(--btn-bg-main-hvr, #015691);
    }
    &:not([target="_blank"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"])::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: rem(32);
      margin: auto;
      width: rem(24);
      height: 2px;
      background-color: var(--btn-clr-main, #fff);
      transition: var(--transit-default, all .4s ease-out);
    }
    &:hover::before {
      animation: arrow .8s cubic-bezier(.5, -.1, .5, 1) forwards;
    }
  }
  .btn.font-en a {
    padding-top: 1.1em;
    padding-bottom: .66em;
  }
  .flex > .btn a { max-width: 100%; }
  .btn.btn-ctr a {
    @include auto-margin;
  }
  .btn svg {
    fill: var(--btn-clr-main, #fff);
  }
  .btn.btn-next_page a {
    max-width: 100%;
    text-align: left;
  }

  /* --- btn-wh --- */
  .btn-wh a {
    color: var(--btn-clr-wht, #333);
    border: 2px solid;
    background-color: var(--btn-bg-wht, #fff);
    &:hover {
      color: var(--btn-clr-wht-hvr, #fff);
      border-color: var(--btn-bg-wht-hvr, #333);
      background-color: var(--btn-bg-wht-hvr, #333);
    }
    &:hover svg {
      fill: var(--btn-clr-wht-hvr, #fff);
    }
    &:not([target="_blank"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"])::before {
      background-color: var(--btn-bg-wht-hvr, #333);
    }
    &:hover::before {
      background-color: var(--btn-clr-wht-hvr, #fff) !important;
    }
  }
  .btn-wh svg {
    fill: var(--btn-clr-wht, #333);
  }

  @include mq(sp) {
    .flex > .btn:not(:last-child) {
      margin-bottom: 8px !important;
    }
  }