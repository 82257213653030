@use '../abstracts/' as *;

/* home_news
********************************************** */
  .home_news {
    padding-top: 48px;
    padding-bottom: 80px;
    @include mq(sp) {
      padding-top: 44px;
      padding-bottom: 24px;
    }
  }
  .home_news .inner {
    @include mq(sp, min, ps) {
      width: calc(100% - 180px);
    }
  }
  .home_news--blc {
    display: grid;
    grid-template-columns: 120px auto 160px;
    background-color: var(--clr-wht, #fff);
    border-radius: rem(20);
    box-shadow: 0 0 1rem rgba($color: #333, $alpha: .1);
    @include mq(sp) {
      grid-template-columns: perc(286,366) perc(80,366);
    }
  }
  .news_list--ttl,
  .news_list--btn a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .news_list--ttl {
    padding-top: .25em;
    @include mq(sp, min, ps) {
      border-right: 1px solid var(--clr-off_wht, #f7f7f7);
    }
    @include mq(sp) {
      position: absolute;
      top: .5rem;
      left: 1rem;
    }
  }
  .news_list--btn a {
    border-radius: 0 rem(20) rem(20) 0;
    height: 100%;
    padding-top: .25em;
    &:hover {
      background-color: var(--btn-bg-main-hvr, #015691);
    }
  }
  .home_news .news_list {
    @include mq(sp, min, ps) {
      overflow: hidden;
    }
  }
  .home_news .news_list .post {
    padding-right: 1em;
    @include mq(sp) {
      display: grid !important;
      grid-template-columns: 80px auto;
      padding: 1.5rem 1rem 1rem;
      height: 56px;
    }
  }
  .home_news .news_list .post:not(:last-child) {
    border-bottom: 0;
  }
  .home_news .news_list .post--ttl {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .home_news .arrows {
    top: 0;
    bottom: 0;
    left: -64px;
    margin: auto;
    width: calc(100% + 128px);
    height: 40px;
    pointer-events: none;
    @include mq(sp) {
      display: none;
    }
  }
  .home_news .slick-arrow {
    width: 40px;
    height: 100%;
    border-radius: 50%;
    background-color: rgba($color: #fff, $alpha: .5);
    pointer-events: auto;
  }
  .home_news .slick-arrow::after {
    width: 10px;
    height: 10px;
  }
  .home_news .slick-prev {
    left: 0;
    padding-left: 0;
  }
  .home_news .slick-next {
    right: 0;
    padding-right: 0;
  }