@use '../abstracts' as *;

/* lps_parts--history
********************************************** */
  .lps_parts--history .inner {
    position: relative;
    padding-left: rem(58);
    @include mq(sp) {
      padding-left: rem(36);
    }
    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }
    &:not(.note)::before {
      content: '';
      position: absolute;
      top: 0;
      left: rem(26);
      width: rem(12);
      height: 100%;
      border-radius: rem(6);
      background-color: rgba($color: #fff, $alpha: .5);
      @include mq(sp) {
        left: rem(3);
      }
    }
  }
  .lps_parts--history .history_item {
    position: relative;
    color: #333;
    line-height: 1.8;
    &:not(:last-child) {
      margin-bottom: rem(28);
    }
  }
  .lps_parts--history .history_item .text {
    display: grid;
    grid-template-columns: rem(120) auto;
    @include mq(sp) {
      grid-template-columns: rem(108) auto;
    }
  }
  .lps_parts--history .ttl-03 {
    font-family: var(--font-en, 'Montserrat', sans-serif);
    color: var(--clr-main, #1D7FB7);
    letter-spacing: .08em;
    margin-bottom: 0 !important;
    line-height: 1.2;
    @include mq(sp) {
      font-size: 1.429rem; /* 20px */
    }
    &::before {
      content: '';
      position: absolute;
      top: .5em;
      left: rem(-30);
      @include rect(8);
      border-radius: 50%;
      background-color: var(--clr-main, #1D7FB7);
      @include mq(sp) {
        @include rect(6);
      }
    }
  }
  .lps_parts--history .txt-wh .ttl-03 {
    color: var(--clr-wht, #fff);
  }
  .lps_parts--history .history_item.txt-wh:not(:last-child)::before {
    border-color: var(--clr-wht, #fff);
  }
  .lps_parts--history .txt-wh .ttl-03::after {
    color: var(--clr-main, #1D7FB7);
    background-color: var(--clr-wht, #fff);
  }
  .lps_parts--history .text img {
    max-width: 280px;
    margin-top: 0;
    margin-right: rem(30);
    overhistory: hidden;
  }
  @include mq(sp) {
    .lps_parts--history .text img {
      float: none !important;
      margin-left: 0;
      margin-right: auto;
      margin-bottom: 16px !important;
    }
  }