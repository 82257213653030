@use "../abstracts" as *;

/* breadcrumbs
**************************************** */
  .breadcrumbs {
    @include mq(sp, min, ps) {
      margin-left: 1rem;
      padding-left: 2rem;
      &::before {
        content: '';
        position: absolute;
        top: .8em;
        left: 0;
        width: 1rem;
        height: 2px;
        background-color: var(--clr-main, #1D7FB7);
      }
    }
  }
  .breadcrumbs a {
    color: var(--clr-main, #1D7FB7);
  }
  .breadcrumbs a:hover {
    text-decoration: underline;
  }
  .breadcrumbs .arrow {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3px 0 3px 5px;
    border-color: transparent transparent transparent var(--clr-main, #008AD5);
    margin-bottom: 2px;
    margin-left: 8px;
    margin-right: 8px;
  }