@use "../abstracts" as *;

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  .inner {
    padding-top: 40px;
    @include mq(sp) {
      padding-top: 24px;
    }
  }
}
.page_ttl--bg-wrap {
  padding-top: perc(560,1200);
  overflow: hidden;
  @include mq(sp, min, ps) {
    border-radius: rem(20);
  }
  @include mq(sp) {
    width: perc(414,350);
    padding-top: perc(193,414);
    margin-left: perc(-32,350);
    height: 193px;
  }
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}
.page_ttl--bg {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-size: cover;
  background-position: center;
}
.page_ttl-jp {
  position: relative;
  font-size: 2.5rem; /* 40px */
  letter-spacing: .2em;
  &:not(:last-child) {
    margin-bottom: .75rem;
  }
  @include mq(sp) {
    font-size: 1.714rem; /* 24px */
  }
  &::before {
    content: '';
    position: absolute;
    top: -20%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100vw;
    padding-top: calc(504/1601 * perc(1601,1200));
    background-image: url(../images/common/logo-white.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1601px;
    opacity: .33;
    z-index: -1;
    pointer-events: none;
    @include mq(1350) {
      background-size: max(1280px, perc(1601,1350));
    }
    @include mq(sp) {
      top: 0;
      left: spvw(-32);
      transform: none;
      background-position: top spvw(32) right spvw(-5);
      background-size: perc(333,414);
    }
  }
}
.page_ttl--info {
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
  @include mq(sp) {
    align-items: flex-start;
  }
}
.page_ttl-en {
  display: block;
  text-transform: uppercase;
  font-family: var(--font-en, "Montserrat", sans-serif);
  font-weight: bold;
  line-height: 1.8;
  @include mq(sp) {
    font-size: .857rem; /* 12px */
  }
}
.page_ttl--list {
  border-radius: rem(20);
  background-color: #fff;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  @include mq(sp, min, ps) {
    &.row-single > * {
      width: auto;
      flex: 1;
    }
    &.col1 { max-width: 240px; }
    &.col2 { max-width: 480px; }
    &.col3 { max-width: 720px; }
    &.col4 { max-width: 960px; }
  }
  @include mq(sp) {
    border-radius: rem(10);
  }
}
.page_ttl--list li {
  border-bottom: 1px dashed var(--bg-off_wht, #f7f7f7);
  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }
  @include mq(sp, min, ps) {
    &:nth-child(5n+1):nth-last-child(-n+5),
    &:nth-child(5n+1):nth-last-child(-n+5) ~ li {
      border-bottom: 0;
    }
    &:not(:nth-child(5n)) {
      border-right: 1px dashed var(--bg-off_wht, #f7f7f7);
    }
  }
  @include mq(sp) {
    &:nth-child(2n+1):nth-last-child(-n+2),
    &:nth-child(2n+1):nth-last-child(-n+2) ~ li {
      border-bottom: 0;
    }
    &:not(:nth-child(2n)) {
      border-right: 1px dashed var(--bg-off_wht, #f7f7f7);
    }
  }
}
.page_ttl--list a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #333;
  padding: 1em;
  text-transform: uppercase;
}
.page_ttl--list a:hover {
  color: #fff;
  background-color: var(--clr-main, #1D7FB7);
}
.page_ttl .cursor_wrap {
  @include mq(sp) {
    position: absolute;
  }
}
.page_ttl .cursor_wrap {
  @include mq(sp) {
    top: 86px;
    right: perc(42,414);
  }
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03 {
  position: relative;
  letter-spacing: .1em;
}
.ttl-01:not(:last-child),
.ttl-02:not(:last-child),
.ttl-03:not(:last-child) {
  margin-bottom: 1em;
}
.ttl-01 {
  font-size: 3rem; /* 48px */
  color: var(--clr-main, #1D7FB7);
  @include mq(sp) {
    font-size: 2rem; /* 28px */
  }
  &::before {
    content: '';
    display: block;
    margin-bottom: 1.5rem;
    @include rect(8);
    border-radius: 50%;
    background-color: var(--clr-main, #1D7FB7);
    @include mq(sp) {
      @include rect(6);
    }
  }
  &.txt-wh::before {
    background-color: var(--clr-wht, #fff);
  }
  &.txt-ctr::before {
    margin-left: auto;
    margin-right: auto;
  }
}
.ttl-01.font-en {
  font-size: rem(80);
  letter-spacing: .04em;
  line-height: 1;
  @include mq(sp) {
    font-size: 2.286rem; /* 32px */
  }
}
.ttl-01-sub {
  display: block;
  color: var(--clr-body, #333);
  font-size: .875rem; /* 14px */
  line-height: var(--line-height-default, 1.8);
  letter-spacing: .2em;
  &:not(:first-child) {
    margin-top: 1rem;
  }
  &::after {
    content: '';
    display: block;
    width: 40px;
    height: 2px;
    background-color: #C9C6AD;
    margin-top: 2rem;
    @include mq(sp) {
      width: 24px;
      margin-top: 1.5rem;
    }
  }
}
.ttl-01.txt-ctr .ttl-01-sub::after,
.ttl-02.txt-ctr .ttl-01-sub::after,
.ttl-02.txt-ctr .ttl-sub::after {
  margin-left: auto;
  margin-right: auto;
}
.ttl-01.txt-wh .ttl-01-sub {
  color: var(--clr-wht, #fff);
  &::after {
    background-color: var(--clr-wht, #fff);
  }
}
.ttl-02,
.ttl-03 {
  line-height: 1.8;
}
.ttl-02 {
  font-size: 2rem; /* 32px */
  @include mq(sp) {
    font-size: 1.714rem; /* 24px */
  }
  .first {
    position: absolute;
    top: -.6em;
    left: 0;
    @include mq(sp) {
      top: -.4em;
      font-size: rem(48);
    }
  }
  .first + span {
    display: block;
  }
}
.ttl-02.font-en {
  font-size: rem(56);
  letter-spacing: .04em;
  line-height: 1;
  @include mq(sp) {
    font-size: rem(36);
  }
}
.ttl-02 .ttl-01-sub::after {
  margin-top: rem(24);
  @include mq(sp) {
    margin-top: rem(16);
  }
}
.ttl-sub {
  display: block;
  color: var(--clr-main, #1D7FB7);
  font-size: .813rem; /* 13px */
  letter-spacing: .08em;
  text-transform: uppercase;
  @include mq(sp) {
    font-size: .643rem; /* 9px */
  }
  &:not(:first-child) {
    margin-top: 1rem;
  }
  &::after {
    content: '';
    display: block;
    width: 40px;
    height: 2px;
    background-color: var(--clr-main, #1D7FB7);
    margin-top: 2rem;
    @include mq(sp) {
      width: 24px;
      margin-top: 1.5rem;
    }
  }
}
.ttl-02.txt-wh .ttl-sub {
  color: var(--clr-wht, #fff);
  &::after {
    background-color: var(--clr-wht, #fff);
  }
}
.ttl-03 {
  font-size: 1.5rem; /* 24px */
  @include mq(sp) {
    font-size: 1.714rem; /* 24px */
  }
}
.ttl-03.font-en {
  font-size: 2rem; /* 32px */
  @include mq(sp) {
    font-size: 1.75rem; /* 28px */
  }
}
.lps_parts--img_text .inner-lg .ttl-03 {
  font-size: 2rem; /* 32px */
  @include mq(sp) {
    font-size: 1.714rem; /* 24px */
  }
}
.lps_sec .column-3 .ttl-03,
.lps_sec .column-4 .ttl-03 {
  font-size: 1.125rem; /* 18px */
  @include mq(sp) {
    font-size: 1.714rem; /* 24px */
  }
}