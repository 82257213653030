@use '../abstracts' as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

/* main_column
**************************************** */
.main_column {
  flex: 1;
  margin-right: 80px;
  overflow: hidden;
}

/* --- post --- */
.post {
  position: relative;
}
.post--img {
  overflow: hidden;
  background-color: var(--clr-wht, #fff);
}
.post--img img:not(.img-cover) {
  max-width: 80%;
}
.hover-lg .post--img img:not(.icn-detail) {
  transition: all .8s ease-out;
}
.hover-lg:hover .post--img img:not(.icn-detail) {
  transform: scale(1.05);
}
.post--link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
} 
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: rem(13);
  background-color: var(--clr-main, #1D7FB7);
  color: #fff;
  padding: .25em 1em;
  float: left;
  white-space: nowrap;
  margin: 2px;
  border-radius: rem(4);
}
.cat_list a:hover {
  opacity: .7;
}

@include mq(1100) {
  .container > .inner.flex {
    display: block;
  }
  .main_column {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 0;
  }
}

/* side_column
**************************************** */
.side_column {
  width: 264px;
  @include mq(1100) {
    width: 100%;
  }
}
.side_section:not(:last-child) {
  margin-bottom: 64px;
}
.side--ttl {
  letter-spacing: .2em;
  line-height: 1;
  margin-bottom: 16px;
}
.side--ttl small {
  opacity: .5;
  letter-spacing: .15em;
  display: block;
  line-height: 1;
  margin-top: 8px;
}

/* --- post --- */
.posts-side .post:not(:last-child) {
  margin-bottom: 16px;
}
.posts-side .post--img {
  margin-right: 16px;
  width: 64px;
  height: 64px;
}
.posts-side .txtarea {
  flex: 1;
}
.posts-side .post--ttl {
  line-height: 1.4;
}

/* --- archive --- */
.side_column .archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
}
.side_column .archive_list--ttl::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotate(135deg);
  transition: var(--trans-default, all .4s ease-out);
}
.side_column .archive_list--ttl.active::after {
  transform: rotate(315deg);
}
.side_column .archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 1;
}
.archive-pulldown .archive_list {
  position: relative;
}
.archive-pulldown .archive_list:not(:last-child) {
  margin-right: 24px;
}
.archive-pulldown .archive_list a {
  display: block;
  padding: .25em 1em;
  text-align: left;
}
.archive-pulldown .archive_list a:not(:last-child) {
  border-bottom: 1px solid #ccc;
}
.archive-pulldown .archive_list a:hover {
  background-color: #eee;
}
.archive-pulldown .archive_list--label {
  margin-right: 8px;
}
.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: .25em 4em .25em 2em;
  color: var(--clr-body, #333);
  background-color: #fff;
  border: 1px solid #aaa;
  font-family: var(--font-primary, "YuGothic", "游ゴシック", "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic ProN", sans-serif);
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: .08em;
  border-radius: rem(10);
  transition: all .2s ease-out;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: .4em;
    right: .8em;
    margin: auto;
    width: .6em;
    height: .6em;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    transition: all .2s ease-out;
  }
}
.active .archive_list--btn {
  border-radius: rem(10) rem(10) 0 0;
  &::after {
    bottom: -.2em;
    transform: rotate(225deg);
  }
}
.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  border: 1px solid #aaa;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: all .2s ease-out;
  border-radius: 0 0 rem(10) rem(10);
}
.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}


/* works
**************************************** */
.posts-works .post {
  background-color: var(--clr-wht, #fff);
  border-radius: rem(20);
  overflow: hidden;
  @include mq(sp) {
    border-radius: rem(10);
  }
}
.posts-works .post--img::before {
  padding-top: perc(256,379);
}
.posts-works .post--info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: rem(8);
}
.posts-works .post--txtarea {
  word-break: break-word;
  padding: rem(24);
}
.posts-works .post--ttl,
.posts-works .post--txt {
  overflow: hidden; 
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.posts-works .post--ttl {
  -webkit-line-clamp: 2;
  &:not(:last-child) {
    margin-bottom: .5em;
  }
}
.posts-works .post--txt {
  -webkit-line-clamp: 3;
}

/* news
**************************************** */

/* --- news--archive --- */
.news--archive {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #111;
}
.news--archive li {
  @include auto-margin(8);
}
.news--archive a {
  color: #fff;
}

/* --- news_list --- */
.news_list .post {
  font-size: .938rem; /* 15px */
  padding-bottom: .88em;
  padding-top: .88em;
  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
  @include mq(sp, min, ps) {
    display: grid !important;
    grid-template-columns: 157px auto;
  }
  @include mq(sp) {
    display: block;
  }
}
.news_list .post--info {
  @include mq(sp, min, ps) {
    width: 157px;
  }
  @include mq(sp) {
    justify-content: flex-start;
  }
}
.news_list .post--date {
  padding-top: .25em;
  opacity: .5;
}
.news_list .post--link {
  color: #333;
}
.news_list .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main, #1D7FB7);
}
.news_list .post--link:hover svg {
  fill: var(--clr-main, #1D7FB7);
}
