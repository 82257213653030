@use '../abstracts/' as *;

/* home_links
********************************************** */
  .home_links--list {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: perc(36,1246);
    @include mq(sp, min, ps) {
      max-width: 964px;
      margin-left: auto;
      margin-right: auto;
    }
    @include mq(sp) {
      grid-template-columns: repeat(2,1fr);
      gap: 8px;
    }
  }
  .home_links--list a {
    display: block;
    text-align: center;
    padding: rem(8) rem(16);
    border-radius: rem(10);
    background-color: var(--clr-wht, #fff);
    &:hover {
      opacity: .7;
    }
  }