@use '../abstracts' as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 169px;
    z-index: z(header);
    transition: var(--transit-default, all .4s ease-out);
    & > .inner {
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
    @include mq(med, min, ps) {
      & > .inner {
        padding-top: 56px;
        padding-left: perc(69,1920);
        padding-right: perc(72,1920);
      }
    }
    @include mq(med) {
      height: 64px;
      & > .inner {
        align-items: center;
        padding-left: perc(24,414);
        padding-right: perc(24,414);
      }
    }
  }
  .page-contact:not(.page-thanks) .header {
    position: absolute;
  }
  .header--logo a,
  .footer--logo a {
    display: block;
  }
  .header--logo a:hover,
  .footer--logo a:hover {
    opacity: .7;
  }
  .header--txt {
    line-height: 1.2;
    text-transform: uppercase;
    white-space: nowrap;
    transition: all .4s ease-out;
    @include mq(med) {
      &.sp-none {
        display: none;
      }
      &.pc-none {
        display: block;
      }
    }
  }
  .header--txt span {
    display: block;
    opacity: .5;
    margin-top: 1em;
  }

  @include mq(med, min, ps) {
    .header-sm:not(.active) {
      height: 104px;
      background-color: var(--bg-off_wht, #f7f7f7);
      .inner {
        padding-top: 0;
        align-items: center;
      }
    }
    .header--logo_wrap {
      position: relative;
    }
    .header--logo a,
    .home .header-sm .header--logo_wrap .header--txt {
      position: absolute;
      top: 0;
      left: 0;
    }
    .home .header--logo a,
    .home .header-sm .header--logo_wrap .header--txt {
      opacity: 0;
      pointer-events: none;
    }
    .home .header-sm .header--logo a {
      opacity: 1;
      pointer-events: auto;
    }
    .header-sm:not(.active) .header--logo a {
      transform: translateY(-50%);
    }
    .subpage .header--logo a {
      width: 300px;
    }
    .home .header--logo a,
    .subpage .header-sm .header--logo a {
      width: 180px;
    }
    .header--logo img {
      width: 100%;
    }
    .fixed_btn--list {
      position: fixed;
      bottom: 45px;
      left: perc(40,1920);
      z-index: z(aside);
      line-height: 1.3;
      letter-spacing: .02em;
      transition: var(--transit-default, all .4s ease-out);
      opacity: 0;
    }
    .header-sm .fixed_btn--list,
    .active .fixed_btn--list {
      opacity: 1;
    }
    .fixed_btn--list li:not(:last-child) {
      margin-bottom: 16px;
    }
    .fixed_btn--list a {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .fixed_btn--list .icon {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      @include rect(56);
      border-radius: 50%;
      margin-bottom: .5em;
      background-color: #0094D6;
      transition: all .4s ease-out;
    }
    .fixed_btn--list .dot {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      @include rect(28);
      border-radius: 50%;
      animation-name: rotate;
      animation-iteration-count: infinite;
      animation-duration: 10s;
      animation-timing-function: linear;
    }
    .fixed_btn--list .dot::before {
      content: '';
      position: absolute;
      top: 0;
      @include rect(5);
      border-radius: 50%;
      background-color: #FED900;
      transition: all .4s ease-out;
    }
    .fixed_btn-reservation .dot::before {
      left: 0;
    }
    .fixed_btn-contact .dot::before {
      right: 0;
    }
    .fixed_btn--list a:hover {
      .icon {
        background-color: var(--clr-wht, #fff);
      }
      .dot::before {
        opacity: 0;
      }
    }
  }
  @include mq(med) {
    .header--logo_wrap {
      z-index: z(top);
    }
    .header--logo img {
      width: auto;
      max-height: 40px;
    }
    .fixed_btn--list {
      display: none;
    }
  }

/* gnav
********************************************** */
  .gnav_btn {
    position: fixed;
    top: auto;
    right: perc(72,1920);
    transition: var(--transit-default, all .4s ease-out);
    width: 128px;
    height: 44px;
    cursor: pointer;
    z-index: z(top);
    @include mq(1540) {
      width: 100px;
    }
    @include mq(med) {
      top: 28px;
      right: perc(24,414);
      width: 85px;
      height: 24px;
    }
  }
  .gnav_btn--txt {
    position: relative;
    margin-top: -8px;
    margin-right: 4px;
    width: 60px;
    white-space: nowrap;
    @include mq(sp) {
      font-size: rem(9);
    }
  }
  .gnav_btn--txt span {
    position: absolute;
    top: 0;
    left: 0;
    transition: all .4s ease-out;
  }
  .gnav_btn--txt .close,
  .active .gnav_btn--txt .sitemap {
    opacity: 0;
  }
  .active .gnav_btn--txt .close {
    opacity: 1;
  }
  .gnav_btn--lines {
    position: relative;
    width: perc(64,128);
    height: 40px;
    @include mq(1540) {
      height: 32px;
    }
    @include mq(med) {
      width: 32px;
      height: 21px;
    }
  }
  .gnav_btn--lines span {
    position: absolute;
    right: 0;
    width: 100%;
    height: 3px;
    transition: var(--transit-default, all .4s ease-out);
    background-color: var(--clr-main, #1D7FB7);
    @include mq(med) {
      height: 2px;
    }
  }
  .gnav_btn--lines span:nth-of-type(1) {
    width: perc(48,64);
    top: 0;
    @include mq(med) {
      width: perc(24,32);
    }
  }
  .gnav_btn--lines span:nth-of-type(2) {
    top: calc(50% - 1.5px);
    @include mq(med) {
      top: calc(50% - 1px);
    }
  }
  .gnav_btn--lines span:nth-of-type(3) {
    bottom: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(1) {
    transform: translateY(18px) rotate(-30deg);
    width: 100%;
    @include mq(1540) {
      transform: translateY(14.5px) rotate(-30deg);
    }
    @include mq(med) {
      transform: translateY(9.5px) rotate(-45deg);
    }
  }
  .active .gnav_btn--lines span:nth-of-type(2) {
    opacity: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(3) {
    transform: translateY(-18px) rotate(30deg);
    @include mq(1540) {
      transform: translateY(-14.5px) rotate(30deg);
    }
    @include mq(med) {
      transform: translateY(-9.5px) rotate(45deg);
    }
  }
  .gnav2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #282828;
    padding: 44px perc(46,1920) 44px perc(44,1920);
    z-index: z(aside);
    transition: var(--transit-default, all .4s ease-out);
    opacity: 0;
    pointer-events: none;
    @include mq(med, min, ps) {
      padding-top: min(vh(44), 44px);
      padding-bottom: min(vh(44), 44px);
      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 2059px;
        height: 648px;
        background-image: url(../images/common/logo-white.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        opacity: .03;
        z-index: -1;
        pointer-events: none;
      }
      &::before {
        top: -409px;
      }
      &::after {
        bottom: -222px;
      }
    }
    @include mq(med) {
      padding: 87px perc(70,414) 24px perc(24,414);
      overflow: auto;
    }
  }
  .active .gnav2 {
    opacity: 1;
    pointer-events: auto;
  }
  .gnav2 .header--logo {
    @include mq(med) {
      display: none;
    }
  }
  .gnav2 .header--logo a {
    position: static;
    opacity: 1;
    pointer-events: auto;
    width: 211px;
  }
  .gnav2 .header--logo img {
    @include mq(med, min, ps) {
      height: min(vh(64), 64px);
    }
  }
  .gnav2 .header--txt {
    display: block;
    margin-top: 1.5em;
    color: #D7D4BF;
    @include mq(med, min, ps) {
      font-size: min(vh(10), 10px);
    }
  }
  .gnav2--content {
    @include mq(med, min, ps) {
      display: flex;
      align-items: flex-end;
      max-width: 1656px;
      margin-top: 1.5em;
      margin-left: auto;
      margin-right: auto;
      font-size: min(vh(16), 16px);
    }
    @include mq(med) {
      margin-bottom: 22px;
    }
  }
  .gnav2--menu {
    @include mq(med, min, ps) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      flex: 1;
      max-width: 1237px;
      height: calc(100vh - 200px);
      margin-left: auto;
      @include mq(1600) {
        margin-left: perc(125,1237);
        height: calc(100vh - min(vh(200), 200px));
      }
    }
    @include mq(med) {
      border-top: 1px solid rgba(215,212,191,.2);
      @include mq(sp, min, ps) {
        display: grid;
        grid-template-columns: repeat(2,1fr);
      }
    }
    svg {
      margin-left: rem(8);
      fill: rgba(215,212,191,.8);
    }
  }
  .ipad .gnav2--menu {
    height: 824px;
  }
  .gnav2--menu > .menu-item {
    @include mq(med, min, ps) {
      width: calc(100% / 3);
      padding-right: 1.25em; /* 20px */
      &:not(:last-child) {
        margin-bottom: 5em; /* 80px */
      }
    }
    @include mq(med) {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid rgba(215,212,191,.2);
      @include mq(sp, min, ps) {
        grid-column: 1/3;
      }
    }
  }
  .gnav2--menu .menu-item .font-en {
    display: block;
    color: var(--clr-main, #1D7FB7);
    text-transform: uppercase;
    @include mq(med, min, ps) {
      font-size: min(vh(18), 18px);
    }
    @include mq(med) {
      width: 285px;
      display: flex;
      align-items: center;
      @include mq(sp, min, ps) {
        line-height: 1;
      }
    }
    @include mq(sp) {
      width: 104px;
      font-size: 1.071rem; /* 15px */
    }
  }
  .gnav2--menu a:not([href="javascript:void(0);"]):hover {
    opacity: .7;
  }
  .gnav2 .gnav2--menu a[href="javascript:void(0);"]:hover {
    color: rgba(255,255,255,.8);
  }
  .gnav2--menu > li > a,
  .head_sub_nav2--wrap {
    @include mq(med) {
      display: none;
      width: calc(100% - 285px);
    }
    @include mq(sp) {
      width: calc(100% - 104px);
    }
    .head_sub_nav2--wrap {
      margin-top: 0;
      width: calc(100% - 2em);
      @include mq(med) {
        display: block;
        padding-bottom: 0;
      }
    }
  }
  .gnav2--menu > .menu-item > a {
    display: block;
    color: rgba(215,212,191,.8);
    font-size: rem(18);
    @include mq(med, min, ps) {
      font-size: min(vh(18), 18px);
    }
    @include mq(med) {
      font-size: rem(20);
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-right: 1rem;
      margin-left: -285px;
      padding-left: 285px;
      width: 100%;
    }
    @include mq(sp) {
      font-size: sprem(15);
      margin-left: -104px;
      padding-left: 104px;
    }
  }
  .gnav2 .gnav2--menu > .menu-item > a[href="javascript:void(0);"]:hover {
    color: rgba(215,212,191,.8);
  }
  .head_sub_nav2--wrap {
    margin-top: .5em;
    margin-left: auto;
    @include mq(med) {
      margin-top: -.5rem;
      padding-bottom: 1rem;
    }
  }
  .head_sub_nav2 a {
    position: relative;
    display: block;
    padding-top: .1em;
    padding-bottom: .1em;
    padding-left: 2em;
    font-size: .938rem; /* 15px */
    color: rgba(255,255,255,.8);
    @include mq(med, min, ps) {
      font-size: min(vh(15), 15px);
    }
    @include mq(sp) {
      font-size: .929rem; /* 13px */
    }
    &::before {
      content: '';
      position: absolute;
      top: 1em;
      left: 0;
      width: 1.067em; /* 16px */
      height: 2px;
      background-color: #008AD5;
    }
    svg {
      @include mq(med, min, ps) {
        width: min(vh(14), 14px);
        height: min(vh(14), 14px);
      }
      fill: rgba(255,255,255,.8);
    }
  }
  .head_sub_nav2 .head_sub_nav2 a::before {
    opacity: .5;
  }
  .gnav2_btn--list {
    @include mq(med, min, ps) {
      position: absolute;
      top: 0;
      right: perc(40,1656);
    }
    @include mq(med) {
      margin-top: 24px;
    }
  }
  .gnav2_btn {
    @include mq(med) {
      @include mq(sp, min, ps) {
        margin-top: rem(24);
      }
    }
    &:not(:last-child) {
      margin-bottom: min(vh(16), 16px) !important;
      @include mq(med) {
        @include mq(sp, min, ps) {
          margin-bottom: 0 !important;
          margin-right: rem(16);
        }
      }
    }
  }
  .gnav2_btn a {
    color: var(--clr-wht, #fff) !important;
    padding: 0 !important;
    max-width: 320px;
    overflow: hidden;
    @include mq(med, min, ps) {
      flex-direction: column;
      justify-content: center;
      width: min(vh(150), 150px);
      height: min(vh(150), 150px);
      text-align: center;
    }
    @include mq(med) {
      br {
        display: none;
      }
      @include mq(sp, min, ps) {
        max-width: 100%;
      }
    }
  }
  .gnav2_btn.footer_btn-reservation a {
    background-color: var(--clr-main, #1D7FB7);
  }
  .gnav2_btn .icon {
    margin-right: 0 !important;
    @include rect(72);
    @include mq(med, min, ps) {
      width: min(vh(72), 72px);
      height: min(vh(72), 72px);
      img[width="33"] {
        width: min(vh(33), 33px);
      }
      img[width="30"] {
        width: min(vh(30), 30px);
      }
    }
    @include mq(med) {
      background-color: #0074B2;
    }
    @include mq(sp) {
      @include rect(64);
    }
  }
  .gnav2_btn .txt {
    @include mq(med) {
      padding: .5em 1em;
      line-height: 1.2 !important;
    }
  }
  .gnav2_btn .font-en {
    color: #D7D4BF !important;
    opacity: 1 !important;
    font-size: min(vh(10), 10px);
  }
  .gnav2--illust {
    margin-bottom: min(vh(66), 66px);
    margin-right: perc(40,1656);
    width: min(vh(155), 155px);
    height: auto;
    @include mq(med) {
      display: none;
    }
  }
  .gnav2 .sns_area {
    @include mq(med, min, ps) {
      bottom: 92px;
      right: perc(46,1920);
    }
    @include mq(med) {
      position: fixed;
      top: 86px;
      right: perc(24,414);
    }
  }
  .gnav2 .sns--list a {
    @include mq(sp) {
      @include rect(20);
    }
  }
  .header .gnav2--copyright {
    @include mq(med) {
      justify-content: flex-start;
    }
  }
  .gnav2--copyright * {
    color: var(--bg-off_wht, #f7f7f7);
  }
  .header .gnav2--copyright > *:not(:last-child) {
    @include mq(med, min, ps) {
      margin-right: 1.5em;
    }
  }
  .gnav2--copyright a:hover {
    opacity: .7;
  }
  .header .gnav2--copyright .copyright {
    @include mq(med) {
      position: fixed;
      bottom: 8px;
      right: perc(24,414);
      transform-origin: right top;
      transform: rotate(90deg);
    }
  }

  /* ---CTA --- */
  .cta_tel a {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.4;
  }
  .cta_tel a > span {
    display: block;
  }
  .cta_tel .tel {
    font-size: 1.5rem; /* 24px */
  }
  .cta_tel .num {
    font-size: 1.875rem; /* 30px */
  }
  .gnav--tel,
  .gnav--contact {
    margin-left: 8px;
  }
  .gnav--tel a {
    display: flex !important;
    flex-direction: column;
  }
  .gnav--contact a {
    padding: 24px;
  }
  .gnav--contact svg {
    fill: #fff;
    margin-right: 8px;
  }
  .gnav--cta .tel {
    font-size: 1.25rem; /* 20px */
  }
  .gnav--cta .num {
    font-size: 1.5rem; /* 24px */
  }
  .gnav--cta .hours {
    font-size: .875rem; /* 14px */
  }

  @include mq(med, min, ps) {
    .gnav,
    .gnav ul,
    .gnav li,
    .gnav a {
      height: 100%;
    }
    .gnav {
      display: flex;
      font-size: .938rem; /* 15px */
      height: 40px !important;
      margin-left: auto;
      padding-right: 176px;
      @include mq(1540) {
        padding-right: 116px;
      }
    }
    .gnav a {
      display: flex;
      @include center-flex;
    }
    .gnav--link {
      text-align: center;
      padding: 0 1.5em;
      @include mq(1540) {
        padding: 0 1em;
      }
      @include mq(pc) {
        padding: 0 .5em;
      }
    }
    .menu-item-has-children {
      position: relative;
    }
    .gnav--menu > li > .gnav--link:not([href="javascript:void(0);"]):hover,
    .gnav--menu > li.current-menu-item > .gnav--link {
      color: #0094D6;
    }
    .gnav_sub_toggle {
      position: absolute;
      top: 0;
      bottom: 0;
      right: .5em;
      margin: auto;
      width: 8px;
      height: 5px;
      @include mq(1540) {
        right: .2em;
      }
      @include mq(pc) {
        right: -.2em;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        border-color: #ccc transparent transparent transparent;
      }
    }
    .head_sub_nav .gnav_sub_toggle {
      transform: rotate(-90deg);
      right: 1.25em !important;
    }
    .gnav--menu .head_sub_nav--wrap {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 15em;
      z-index: 100;
      transition: var(--transit-default, all .4s ease-out);
      opacity: 0;
      pointer-events: none;
    }
    .gnav--menu .menu-item-has-children:hover > .head_sub_nav--wrap {
      opacity: 1;
      pointer-events: auto;
    }
    .head_sub_nav > li {
      &:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
      &:first-child > a {
        border-radius: rem(10) rem(10) 0 0;
      }
      &:last-child > a {
        border-radius: 0 0 rem(10) rem(10);
      }
    }
    .head_sub_nav a {
      display: block;
      padding: 1em;
      background-color: #fff;
      svg {
        fill: var(--clr-body, #333);
        margin-left: rem(8);
      }
    }
    .head_sub_nav a:hover {
      opacity: .7;
    }
    .gnav--menu .head_sub_nav--wrap .head_sub_nav--wrap {
      top: 0;
      left: 100%;
      transform: none;
      border-left: 1px solid #eee;
    }

    /* ---CTA --- */
    .gnav--contact a {
      border-radius: 0;
    }
  }
  @include mq(med) {
    .gnav {
      display: none;
    }
    .gnav--menu,
    .gnav--cta {
      display: block;
    }
    .gnav--menu li {
      position: relative;
      display: block;
      width: 100%;
    }
    .gnav--menu li:not(:last-child) {
      border-bottom: 1px dotted var(--clr-main, #1D7FB7);
    }
    .gnav--menu a {
      display: block;
      padding: 18.5px 16px;
    }
    .gnav--menu li:not(.menu-item-has-children) a::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 10px;
      margin: auto;
      width: 7px;
      height: 7px;
      border-top: 1px solid var(--clr-main, #1D7FB7);
      border-right: 1px solid var(--clr-main, #1D7FB7);
      transform: rotate(45deg);
    }
    .head_sub_nav--wrap {
      width: 100%;
      border-top: 1px dotted var(--clr-main, #1D7FB7);
      display: none;
    }
    .menu-item-has-children.active .head_sub_nav--wrap {
      opacity: 1;
      pointer-events: auto;
    }
    .head_sub_nav a {
      padding-left: 32px !important;
    }
    .gnav_sub_toggle {
      position: absolute;
      top: 0;
      right: -20px;
      width: 64px;
      height: 64px;
      transition: var(--transit-default, all .4s ease-out);
      color: var(--clr-main, #1D7FB7);
    }
    .gnav_sub_toggle::before,
    .gnav_sub_toggle::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 11px;
      height: 1px;
      border-bottom: 1px solid;
    }
    .gnav_sub_toggle::after {
      transform: rotate(90deg);
    }
    .gnav_sub_toggle.active {
      transform: rotate(180deg);
    }
    .head_sub_nav {
      position: relative;
    }
    .gnav2--menu > li > .gnav_sub_toggle2 {
      position: absolute;
      top: 0;
      right: 0;
      @include rect(72);
      transition: var(--transit-default, all .4s ease-out);
      @include mq(sp) {
        @include rect(59);
      }
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 1rem;
        height: 1px;
        border-bottom: 1px solid;
        transition: var(--transit-default, all .4s ease-out);
      }
      &::after {
        transform: rotate(90deg);
      }
      &.active {
        transform: rotate(180deg);
        &::after {
          opacity: 0;
        }
      }
    }

    /* ---CTA --- */
    .gnav--cta li:not(:last-child) {
      margin-bottom: 16px;
    }
    .gnav--tel {
      margin-left: 0;
      text-align: center;
    }
    .gnav--contact {
      margin-left: 0;
    }
    .gnav--contact a {
      padding: 10px 16px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @include mq(sp) {
    .gnav--menu a {
      padding: 19px 16px;
    }
    .gnav--contact svg {
      width: 16px;
      height: 11px;
    }
  }