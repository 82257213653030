@use '../abstracts' as *;

/* Wysiwyg editor
 **************************************** */
.mce-content-body ol {
  list-style: decimal;
}
.mce-content-body ul:not(:first-child),
.mce-content-body ol:not(:first-child) {
  margin-top: 1em;
}
.mce-content-body ul:not(:last-child),
.mce-content-body ol:not(:last-child) {
  margin-bottom: 1em;
}
.mce-content-body ul li,
.mce-content-body ol li {
  position: relative;
  margin: 0;
  border-bottom: none;
  padding: 0;
  margin-left: 2em;
  padding-top: .5em;
}
.mce-content-body ul li {
  list-style: none;
}
.mce-content-body ul li::before {
  content: '';
  position: absolute;
  top: 1.15em;
  left: -1.3em;
  @include rect(10);
  border: 1px solid var(--clr-main, #1D7FB7);
  background-color: var(--clr-main, #1D7FB7);
}
.mce-content-body ul[style="list-style-type: circle;"] li::before {
  background-color: #fff;
}
.mce-content-body ul:not([style="list-style-type: square;"]) li::before {
  border-radius: 50%;
}
.mce-content-body img {
  margin: 1em 0;
  max-width: 100%;
  height: auto;
}
.mce-content-body hr {
  border-color: #aaa;
}
.mce-content-body .box {
  padding: 24px 32px;
  background-color: var(--bg-wht, #fff);
  border-radius: rem(20);
  font-size: .875rem; /* 14px */
}
.mce-content-body .box:not(:first-child) {
  margin-top: 2em;
}
.mce-content-body .box:not(:last-child) {
  margin-bottom: 2em;
}
.bg-wh .mce-content-body .box,
.lps_sec-wh .mce-content-body .box {
  background-color: rgba($color: #000, $alpha: .05);
}
.mce-content-body .alignleft {
  float: left;
  clear: left;
  margin-right: 1em;
  margin-bottom: 1em;
}
.mce-content-body .alignright {
  float: right;
  clear: right;
  margin-left: 1em;
  margin-bottom: 1em;
}
.mce-content-body .aligncenter {
  @include auto-margin;
  margin-bottom: 1em;
}
.mce-content-body img.aligncenter {
  display: block;
}
.mce-content-body .alignleft:last-child,
.mce-content-body .alignright:last-child,
.mce-content-body .aligncenter:last-child {
  margin-bottom: 0;
}
.mce-content-body p {
  letter-spacing: .1em;
}
.mce-content-body p:not(:last-child) {
  margin-bottom: 1em;
}
.mce-content-body strong {
  font-weight: bold;
}
.mce-content-body blockquote {
  margin: 1em 0;
  padding: 1em 1.5em;
  border-radius: rem(20);
  position: relative;
  display: inline-block;
  font-style: oblique;
  background-color: var(--clr-wht, #fff);
  @include mq(sp) {
    border-radius: rem(10);
  }
}
.mce-content-body blockquote p { margin-bottom: 0; }
.mce-content-body blockquote::before,
.mce-content-body blockquote::after {
  content: '';
  position: absolute;
  width: 1em;
  height: 1em;
  opacity: .25;
}
.mce-content-body blockquote::before {
  background-image: url('../images/common/icon-quote_left.svg');
  top: 13px;
  left: 10px;
}
.mce-content-body blockquote::after {
  background-image: url('../images/common/icon-quote_right.svg');
  bottom: 13px;
  right: 10px;
}
.mce-content-body a {
  text-decoration: underline;
}
.mce-content-body a:hover {
  text-decoration: none;
}
.mce-content-body .footer_text small {
  display: block;
  margin-top: 8px;
  text-align: right;
}
.mce-content-body table {
  border: none;
  height: auto !important;
}
.mce-content-body table:not(:first-child) { margin-top: 1em; }
.mce-content-body table:not(:last-child) { margin-bottom: 1em; }
.mce-content-body th,
.mce-content-body td,
.mce-content-body tr {
  height: auto !important;
}
.mce-content-body th,
.mce-content-body td {
  padding: 1em;
  border: 1px solid #ccc;
  vertical-align: middle;
}
.mce-content-body th {
  min-width: 120px;
  text-align: left;
}
.mce-content-body sup {
  font-size: .75rem; /* 12px */
  color: #555;
}
.bold {
  font-weight: bold;
  color: var(--clr-main, #1D7FB7);
}

/* iframe */
.mce-content-body .iframe {
  position: relative;
}
.mce-content-body .iframe:not(:last-child) {
  margin-bottom: 2em;
}
.mce-content-body .iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mce-content-body .iframe--youtube,
.mce-content-body .iframe--google_map {
  padding-top: perc(9,16);
}

/* table scroll */
.scroll {
  overflow-x: scroll;
  margin-bottom: 1em;
  -webkit-overflow-scrolling: touch;
}
.scroll table { margin: 0; }
.scroll--cap {
  text-align: right;
  margin: 1em 0 0 !important;
  opacity: .25;
  font-size: .875rem; /* 14px */
}

@include mq(sp) {
  .mce-content-body p:not([class]) {
    text-align: left !important;
  }
  .mce-content-body .alignright,
  .mce-content-body .alignleft  {
    width: 100% !important;
    float: none;
    margin: 1em auto;
    display: block;
  }
  .mce-content-body blockquote {
    padding: 15px;
  }
  .mce-content-body blockquote::before {
    top: 0;
    left: 0;
  }
  .mce-content-body blockquote::after {
    bottom: 0;
    right: 0;
  }
  .mce-content-body ul li::before {
    @include rect(8);
  }
  .mce-content-body table {
    max-width: none !important;
  }
  .mce-content-body th {
    font-weight: bold;
  }
  .mce-content-body th,
  .mce-content-body td {
    padding: .5em 1em;
  }
  .table-sp-full {
    width: 100% !important;
  }
  .table-sp-full th,
  .table-sp-full td {
    flex: 1;
  }
  .table-sp-block,
  .table-sp-block th,
  .table-sp-block td {
    width: 100% !important;
  }
  .table-sp-block th,
  .table-sp-block td {
    display: block;
  }
  .mce-content-body .box {
    padding: 24px;
    border-radius: rem(10);
  }

  /* table scroll */  
  .table-sp-scroll {
    white-space: nowrap;
  }
}
/* 管理画面エディタ用
*************************************************** */
.mce-content-body.wp-editor {
  padding: 24px !important;
}

@include mq(sp) {
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2),
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}
/* add quicktag
*************************************************** */
/* 見出し */
.editor-ttl,
.editor-ttl2,
.editor-ttl3 {
  letter-spacing: .2em;
}
.editor-ttl:not(:first-child),
.editor-ttl2:not(:first-child),
.editor-ttl3:not(:first-child) {
  margin-top: 2.5em;
}
.editor-ttl:not(:last-child),
.editor-ttl2:not(:last-child),
.editor-ttl3:not(:last-child) {
  margin-bottom: 1em;
}
.editor-ttl {
  font-size: 2rem; /* 32px */
}
.editor-ttl2 {
  font-size: 1.5rem; /* 24px */
  letter-spacing: .1em;
}
.editor-ttl3 {
  font-size: 1.125rem; /* 18px */
  letter-spacing: .1em;
}
.editor-ttl3-line {
  color: var(--clr-main, #1D7FB7);
  &::after {
    content: '';
    display: block;
    margin-top: 1em;
    width: 2.222em; /* 40px */
    height: 2px;
    border-bottom: 2px solid;
  }
  &[style*="text-align: center;"]::after {
    margin-left: auto;
    margin-right: auto;
  }
}

/* 注意書き */
.txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  font-size: .875rem; /* 14px */
  color: #999;
}
.txt-attention::before {
  content: '※';
}

/* テーブル */
.table1 th,
.table1 td {
  position: relative;
  border: 0;
  padding: 1rem 1.5rem;
}
.table1 th {
  color: var(--clr-main, #1D7FB7);
  border-bottom: 2px solid;
}
.table1 td {
  font-size: .875rem; /* 14px */
  border-bottom: 1px solid #aaa;
}
.table2 {
  border: 1px solid #aaa !important;
  border-spacing: 0;
  border-collapse: separate;
  border-radius: rem(20);
}
.table2 thead {
  position: relative;
}
.table2 thead::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--clr-main, #1D7FB7);
  opacity: .05;
  border-radius: rem(20) rem(20) 0 0;
}
.table2 th,
.table2 td {
  border: 0;
  font-size: .938rem; /* 15px */
  padding: 1em 1.5em;
}
.table2 th {
  background-color: rgba($color: #000, $alpha: .05);
}
.table2 tr:not(:last-child) th,
.table2 tr:not(:last-child) td {
  border-bottom: 1px solid #ccc;
}
.table2 tr:first-child > *:first-child {
  border-radius: rem(20) 0 0 0;
}
.table2 tr:last-child > *:first-child {
  border-radius: 0 0 0 rem(20);
}
.table2 tr:first-child  > *:last-child {
  border-radius: 0 rem(20) 0 0;
}
.table2 tr:last-child > *:last-child {
  border-radius: 0 0 rem(20) 0;
}
.table2 tr:only-child > *:first-child {
  border-radius: rem(20) 0 0 rem(20);
}
.table2 tr:only-child > *:last-child {
  border-radius: 0 rem(20) rem(20) 0;
}
.table2 thead tr:only-child > *:first-child {
  border-radius: rem(20) 0 0 0;
}
.table2 thead tr:only-child > *:last-child {
  border-radius: 0 rem(20) 0 0;
}
.table2 thead th:not(:first-child) {
  background-color: transparent;
}
.table2 thead tr:last-child:not(:only-child) > *,
.table2 thead + tbody tr:first-child > * {
  border-radius: 0 !important;
}
.table2 thead + tbody tr:first-child th,
.table2 thead + tbody tr:first-child td {
  border-top: 1px solid #ccc;
}

@include mq(sp) {
  .editor-ttl {
    font-size: 1.714rem; /* 24px */
  }
  .editor-ttl2 {
    font-size: 1.286rem; /* 18px */
  }
  .editor-ttl3 {
    font-size: 1.143rem; /* 16px */
  }

  /* テーブル */
  .table1.table-sp-block td::before {
    display: none;
  }
  .table2 {
    border-radius: rem(10);
  }
  .table2 thead::before {
    border-radius: rem(10) rem(10) 0 0;
  }
  .table2 tr:first-child > *:first-child {
    border-radius: rem(10) 0 0 0;
  }
  .table2 tr:last-child > *:first-child {
    border-radius: 0 0 0 rem(10);
  }
  .table2 tr:first-child  > *:last-child {
    border-radius: 0 rem(10) 0 0;
  }
  .table2 tr:last-child > *:last-child {
    border-radius: 0 0 rem(10) 0;
  }
  .table2 tr:only-child > *:first-child {
    border-radius: rem(10) 0 0 rem(10);
  }
  .table2 tr:only-child > *:last-child {
    border-radius: 0 rem(10) rem(10) 0;
  }
  .table2 thead tr:only-child > *:first-child {
    border-radius: rem(10) 0 0 0;
  }
  .table2 thead tr:only-child > *:last-child {
    border-radius: 0 rem(10) 0 0;
  }
  .table2.table-sp-block tr:last-child th,
  .table2.table-sp-block tr:first-child > *:last-child {
    border-radius: 0;
  }
  .table2.table-sp-block tr:first-child > *:first-child {
    border-radius: rem(10) rem(10) 0 0;
  }
  .table2.table-sp-block tr:last-child > *:last-child {
    border-radius: 0 0 rem(10) rem(10);
  }
  .table2.table-sp-block tr:not(:last-child) th,
  .table2.table-sp-block tr:not(:last-child) td {
    border-bottom: 0;
  }

  /* 注意書き */
  .txt-attention {
    font-size: .929rem; /* 13px */
  }
}