/* bg
********************************************** */
.bg-main {
  background-color: var(--bg-main, #1D7FB7);
}
.bg-sub {
  background-color: #eee;
}
.bg-wh {
  background-color: var(--bg-wht, #fff);
}
.bg-bk {
  background-color: var(--bg-blk, #000);
}