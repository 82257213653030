@use '../abstracts' as *;

/* lps_parts--related
********************************************** */
  .page_related.section_pdg {
    padding-bottom: 0;
  }
  .lps_parts--related li {
    position: relative;
    &::before {
      content: '';
      display: block;
      padding-top: perc(400,640);
      @include mq(sp) {
        padding-top: perc(220,414);
      }
    }
  }
  .home .lps_parts--related li:nth-child(1) .related--bg {
    background-image: url(../images/page_related-bg01.jpg);
  }
  .home .lps_parts--related li:nth-child(2) .related--bg {
    background-image: url(../images/page_related-bg02.jpg);
  }
  .home .lps_parts--related li:nth-child(3) .related--bg {
    background-image: url(../images/page_related-bg03.jpg);
  }
  .lps_parts--related a,
  .lps_parts--related span {
    display: block;
    font-weight: bold;
    margin-bottom: 0 !important;
    line-height: 1.6;
  }
  .lps_parts--related a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
    z-index: 1;
    &:hover .related--bg-wrap::before {
      opacity: .9;
    }
  }
  .lps_parts--related .ttl-02 {
    font-size: clamp(rem(22), calc(32/1560*100vw), rem(32));
    @include mq(sp) {
      font-size: sprem(22);
    }
    &::before {
      content: '';
      display: block;
      margin-bottom: .75em;
      @include rect(8);
      border-radius: 50%;
      background-color: var(--clr-main, #1D7FB7);
      margin-left: auto;
      margin-right: auto;
      @include mq(1560) {
        width: calc(8/1560*100vw);
        height: calc(8/1560*100vw);
      }
      @include mq(med) {
        @include rect(6);
      }
    }
  }
  .lps_parts--related li:nth-child(3) .ttl-02::before {
    background-color: var(--clr-off_wht, #f7f7f7);
  }
  .lps_parts--related .font-en {
    font-size: clamp(rem(12), calc(18/1560*100vw), rem(18));
    text-transform: uppercase;
    &.txt-off_wh {
      opacity: .5;
    }
  }
  .related--bg-wrap,
  .related--bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .related--bg-wrap {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all .4s ease-out;
    }
  }
  .lps_parts--related .clr-lgt .related--bg-wrap::before {
    background-color: #555;
  }
  .lps_parts--related .clr-def .related--bg-wrap::before {
    background-color: #444;
  }
  .lps_parts--related .clr-drk .related--bg-wrap::before {
    background-color: #333;
  }
  .lps_parts--related .clr-cta .related--bg-wrap::before {
    background-color: #008CD6;
  }
  .related--bg {
    background-position: center;
    background-size: cover;
  }