/* ##############################################################################

    KEYFRAMES

############################################################################## */

  @keyframes rotate {
    0% { transform: rotate(0); }
    100% { transform: rotate(360deg); }
  }
  @keyframes loop_slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  @keyframes scroll_down {
    0% {
      transform: scale(1,0);
      transform-origin: 0 0;
    }
    50% {
      transform: scale(1,1);
      transform-origin: 0 0;
    }
    50.1% {
      transform: scale(1,1);
      transform-origin: 0 100%;
    }
    100% {
      transform: scale(1,0);
      transform-origin: 0 100%;
    }
  }
  @keyframes arrow {
    0% {
      transform: scale(1,1);
      transform-origin: 100% 0;
    }
    50% {
      transform: scale(0,1);
      transform-origin: 100% 0;
    }
    50.1% {
      transform: scale(0,1);
      transform-origin: 0 0;
    }
    100% {
      transform: scale(1,1);
      transform-origin: 0 0;
    }
  }